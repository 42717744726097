import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {company_module} from "./company";
import {establishment_module} from "./establishment";
import {employees_module} from "./employees";
import {supplier_module} from "./supplier";
import {visitant_module} from "./visitant";
import {jobs_module} from "./jobs";
import {visits_module} from "./visits";
import {base_module} from './base';
import {help_module} from './help';
import {email_module} from './email_zones';
import {zone_module} from './zones';
import {departures_module} from './departures';
import {visits_and_departures_module} from './monitoringDeparturesAndVisits.js';
import {email_setting_module} from "./email_setting.js";
import {income_module} from "./income.js";
import {reason_decline_module} from "./reason_decline.js";
import {bank_module} from "./banks.js";
import { reason_dismissal_module } from "./reason_dismissal.js";

const rootReducer = combineReducers({
    users_module: users_module,
    email_module: email_module,
    company_module: company_module,
    establishment_module: establishment_module,
    employees_module: employees_module,
    supplier_module: supplier_module,
    visitant_module: visitant_module,
    jobs_module: jobs_module,
    visits_module: visits_module,
    base_module: base_module,
    help_module: help_module,
    zone_module: zone_module,
    departures_module: departures_module,
    visits_and_departures_module: visits_and_departures_module,
    email_setting_module: email_setting_module,
    income_module: income_module,
    reason_decline_module: reason_decline_module,
    bank_module: bank_module,
    reason_dismissal_module: reason_dismissal_module,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
