import React from "react";
// import { URLAPI } from "../../../config";
// import { useSelector } from "react-redux";
import "./departeee-modal.css";

const DeparteeModal = ({
                           show = false,
                           children,
                           className,
                           style,
                           onClick,
                           handleClose,
                           cargo,
                           RUC,
                           empresa,
                           photo,
                           nombres,
                           apellidos
                       }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    // const _get_all_visits = useSelector(
    //   (state) => state.visits_module._get_all_visits
    // );
    return (
        <div className={showHideClassName} onClick={onClick}>
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4>{nombres} {apellidos}</h4>
                        <button className='close' onClick={handleClose}>
                            <i className='bx bx-x'/>
                        </button>
                    </div>
                    <div className="modal-body container">
                        <div className="row mt-1 mb-1">
                            <div className="col-md-6 col-sm-12 text-center">
                                {
                                    photo ?
                                        <img
                                            src={photo}
                                            width="100%"
                                            height="70%"
                                            alt="avatar"

                                        /> : <img
                                            src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                            width="100%"
                                            height="100%"
                                            alt="avatar"
                                        />

                                }
                            </div>
                            <div className="col-md-6 col-sm-12 mt-1">
                                <p>{RUC}</p>
                                <p>Empresa: {empresa}</p>
                                <p>Cargo: {cargo}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeparteeModal;


