import React, {useEffect, useState} from "react";
import Button from "./components/button";
import Input from "./components/input";
import InputPassword from "./components/input_password";
// import Alert from "./components/alert";
import {Link, useHistory} from "react-router-dom";
import {APP_NAME} from "./config/index";
import {useDispatch, useSelector} from "react-redux";
import {login, clear_user_module, LOGIN_SUCCESS} from "./redux/users";
import {toast, ToastContainer} from 'react-toastify';

const Login = () => {
    const history = useHistory();
    const _login_data = useSelector((state) => state.users_module.login);
    const [data, setData] = useState({
        username: "",
        password: "",
    });
    // const [error, setError] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        console.log('_login_data')
        console.log(_login_data)
        console.log(_login_data.status)
        if (_login_data.status === 200) {
            history.push("/");
        } else if (_login_data.status !== 0) {
            toast.error(_login_data.message);
            // setError(_login_data.message);
            dispatch(clear_user_module(LOGIN_SUCCESS));
        }

    }, [_login_data, history, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.username === "") {
            toast.error("Debe ingresar el usuario");
            // setError("El usuario es requerido");
            return;
        }
        if (data.password === "") {
            toast.error("Debe ingresar la contraseña");
            // setError("La contraseña es requerida");
            return;
        }
        if (data.username !== "" && data.password !== "") {
            let value = {
                username: data.username.trim(),
                password: data.password,
            }
            dispatch(login(value));
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            <div className="content-wrapper">
                <div className="content-body">
                    {/* login page start */}
                    <section id="auth-login" className="row flexbox-container">
                        <div className="col-xl-8 col-11">
                            <div className="card bg-authentication mb-0">
                                <div className="row m-0">
                                    {/* left section-login */}
                                    <div className="col-md-6 col-12 px-0">
                                        <div
                                            className="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
                                            <div className="card-header pb-1">
                                                <div className="card-title">
                                                    <h4 className="text-center mb-2">{APP_NAME}</h4>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <form onSubmit={handleSubmit}>
                                                        {/* {error != null ? (
                                                            <Alert
                                                                message={error}
                                                                icon="fa-exclamation-circle"
                                                                type="bg-rgba-danger"
                                                                dismisable={true}
                                                            />
                                                        ) : null} */}
                                                        <Input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            value={data.username}
                                                            onChange={(e) =>
                                                                setData({...data, username: e.target.value})
                                                            }
                                                            placeholder="Usuario"
                                                            label="Usuario"
                                                        />
                                                        <InputPassword
                                                            type="password"
                                                            name="password"
                                                            id="password1"
                                                            id_div1="show_hide_password"
                                                            id_div2="password"
                                                            value={data.password}
                                                            onChange={(e) =>
                                                                setData({...data, password: e.target.value})
                                                            }
                                                            placeholder="contraseña"
                                                            label="Contraseña"
                                                        />
                                                        <div
                                                            className="form-group d-flex flex-md-row flex-column justify-content-between align-items-center">
                                                            <div className="text-right">
                                                                <Link to="/recuperar-contrasena" className="card-link">
                                                                    <small>¿Olvidaste la contraseña?</small>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            type="submit"
                                                            classes="btn btn-primary glow w-100 position-relative"
                                                            text="Iniciar Sesión"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* right section image */}
                                    <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                        <div className="card-content">
                                            <img
                                                className="img-fluid"
                                                src={
                                                    window.location.origin +
                                                    "/base/app-assets/images/pages/login.png"
                                                }
                                                alt="branding logo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* login page ends */}
                </div>
            </div>
        </div>
    );
};

export default Login;
