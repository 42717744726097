import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {getLogsEmployees, reportLogsEmployees} from "../../../redux/employees";
import {toast, ToastContainer} from 'react-toastify';
import LoadingSpinner from "../../../components/loading-spinner";

const LogsEmployees = (props) => {
    let dispatch = useDispatch();
    const logs_employees = useSelector(
        (state) => state.employees_module._get_logs_employees
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);
    const [buscar, setBuscar] = useState('');
    const [n_page, setNPage] = useState(0);

    const [page, setPage] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    function more_data() {
        let count = page + 1;
        setPage(count);
        setIsLoading(true);
        dispatch(getLogsEmployees(token, '', count));
    }

    function less_data() {
        let count = page - 1;
        setPage(count);
        setIsLoading(true);
        dispatch(getLogsEmployees(token, '', count));
    }

    useEffect(() => {
        if (logs_employees.data.datos) {
            setData(logs_employees.data.datos);
            setNPage(logs_employees.data.n_page);
        }
        setIsLoading(false);
    }, [logs_employees]);

    function search_history() {
        setIsLoading(true);
        dispatch(getLogsEmployees(token, buscar, 1));
    }

    async function generate_report() {
        setIsLoading(true);
        const params = '?buscar=' + buscar;
        const response = await reportLogsEmployees(token, params);
        toast.success(response.data.mensaje);
        setIsLoading(false);
    }


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[{label: "Historias de empleados", link: "/historia-empleados"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                    <div className="col-lg-6 col-md-6 col-sm-8 col-8">
                                        <div className="form-group">
                                            <label>Buscar</label>
                                            <input className="form-control" name="buscar" onChange={(e) => {
                                                setBuscar(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-4 col-4 mt-1">
                                        <button className="btn btn-success pb-1" onClick={() => {
                                            search_history()
                                        }}><i className="text-white bx bx-search"/></button>
                                    </div>
                                    <div className="col-lg-5 col-md-4 col-sm-12 col-12 text-right">
                                        <button onClick={() => {
                                            generate_report()
                                        }} type="button" class="btn btn-success pb-1">Generar reporte <i
                                            class="bx bx-export"></i></button>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                    <div className="col-4">
                                        <label className="ml-1">Página {page}</label>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(page > 1 && page <= n_page) &&
                                            <button className="btn btn-primary mr-1" type="button" onClick={() => {
                                                less_data();
                                            }}><i class="bx bx-arrow-back"></i></button>
                                        }
                                        {(page >= 1 && page < n_page) &&
                                            <button className="btn btn-success" type="button" onClick={() => {
                                                more_data();
                                            }}><i class="bx bx-right-arrow-alt"></i></button>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered" id="table">
                                        <thead>
                                        <tr className="text-center">
                                            <th>Fecha</th>
                                            <th>Ruc</th>
                                            <th>Nombres</th>
                                            <th>Apellidos</th>
                                            <th>Cargo</th>
                                            <th>Nuevo Cargo</th>
                                            <th>Establecimiento</th>
                                            <th>Nuevo establecimiento</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(d => (
                                                <tr>
                                                    <td>{d.fecha}</td>
                                                    <td>{d.ruc}</td>
                                                    <td>{d.nombres}</td>
                                                    <td>{d.apellidos}</td>
                                                    <td>{d.cargo}</td>
                                                    <td>{d.nuevo_cargo}</td>
                                                    <td>{d.establecimiento}</td>
                                                    <td>{d.nuevo_establecimiento}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogsEmployees;
