import {
    URLAPI,
    SUPPLIER_PATH,
    SUPPLIER_CREATE_PATH,
    SUPPLIER_UPDATE_PATH,
    SUPPLIER_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_suppliers: {
        data: [],

        status: 0,
        message: {},
    },

    _create_supplier: {
        data: {},
        status: 0,
        message: {},
    },
    _update_supplier: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_supplier: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";

export const supplier_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_SUPPLIERS:
            return {
                ...state,
                _get_all_suppliers: action.payload,
            };
        case CREATE_SUPPLIER:
            return {
                ...state,
                _create_supplier: action.payload,
            };
        case UPDATE_SUPPLIER:
            return {
                ...state,
                _update_supplier: action.payload,
            };
        case DELETE_SUPPLIER:
            return {
                ...state,
                _delete_supplier: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllSuppliers = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${SUPPLIER_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_SUPPLIERS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_SUPPLIERS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_SUPPLIERS,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

//create supplier
export const createSupplier = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${SUPPLIER_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_SUPPLIER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_SUPPLIER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_SUPPLIER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateSupplier = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${SUPPLIER_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_SUPPLIER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_SUPPLIER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_SUPPLIER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const deleteSupplier = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${SUPPLIER_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_SUPPLIER,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_SUPPLIER,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_SUPPLIER,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_suppliers = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
