import axios from 'axios';


import {URLAPI, GET_SETTINGS_ALL_PATH} from '../config'

const init = {
    _general_settings_all: {
        data: [],
        status: 0,
        message: {}
    },
}

export const GET_ALL_GENERAL_SETTINGS = "GET_ALL_GENERAL_SETTINGS"

export const base_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_GENERAL_SETTINGS:
            return {...state, _general_settings_all: action.payload}
        default:
            return {...state}
    }
}

export const get_all_general_settings = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${GET_SETTINGS_ALL_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_GENERAL_SETTINGS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_GENERAL_SETTINGS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: "Error al obtener las configuraciones"
                },
            });
        } else {
            dispatch({
                type: GET_ALL_GENERAL_SETTINGS,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener las configuraciones",
                },
            });
        }
    }
}