import React from "react";
import './camera-button.css'
const CameraButton = ({ onClick }) => {
    return (
        <div className="photo-button" onClick={onClick}>
            <div className="circle"></div>
            <div className="ring"></div>
        </div>
    )
}

export default CameraButton;