import axios from 'axios';


const init = {
    _get_page: {
        data: {},
        status: 0,
        error: ""
    }

}


export const GET_NOTION_PAGE = "GET_NOTION_PAGE";

export const help_module = (state = init, action) => {
    switch (action.type) {
        case GET_NOTION_PAGE:
            return {...state, _get_page: action.payload}

        default:
            return state
    }
}


export const getNotionPage = (id_page) => async (dispatch) => {
    try {


        const res = await axios.get('https://notion-api.splitbee.io/v1/page/' + id_page);
        dispatch({
            type: GET_NOTION_PAGE,
            payload: {
                data: res.data,
                status: res.status,
                error: "",
            },
        });

    } catch (error) {
        dispatch({
            type: GET_NOTION_PAGE,
            payload: {
                data: {},
                status: error.response ? error.response.status : 500,
                message: error.message,
            },
        });
    }
}