import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
    return (
        <>
            <div className="loader-container">
                <div className="spinner"/>
            </div>
            <div className="loader-container">
                <h4 style={{marginTop: '10%'}}>Cargando ...</h4>
            </div>
        </>
    );
}