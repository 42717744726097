import {
    URLAPI,
    REASON_DISMISSAL_PATH,
    REASON_DISMISSAL_CREATE_PATH,
    REASON_DISMISSAL_UPDATE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_reason_dismissal: {
        data: [],

        status: 0,
        message: {},
    },

    _create_reason_dismissal: {
        data: {},
        status: 0,
        message: {},
    },
    _update_reason_dismissal: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_REASON_DISMISSAL = "GET_ALL_REASON_DISMISSAL";
export const CREATE_REASON_DISMISSAL = "CREATE_REASON_DISMISSAL";
export const UPDATE_REASON_DISMISSAL = "UPDATE_REASON_DISMISSAL";

export const reason_dismissal_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_REASON_DISMISSAL:
            return {
                ...state,
                _get_all_reason_dismissal: action.payload,
            };
        case CREATE_REASON_DISMISSAL:
            return {
                ...state,
                _create_reason_dismissal: action.payload,
            };
        case UPDATE_REASON_DISMISSAL:
            return {
                ...state,
                _update_reason_dismissal: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllReasonDismissal = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${REASON_DISMISSAL_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_REASON_DISMISSAL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_REASON_DISMISSAL,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_REASON_DISMISSAL,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const createReasonDismissal = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${REASON_DISMISSAL_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_REASON_DISMISSAL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_REASON_DISMISSAL,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_REASON_DISMISSAL,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateReasonDismissal = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${REASON_DISMISSAL_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_REASON_DISMISSAL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_REASON_DISMISSAL,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_REASON_DISMISSAL,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const clear_reason_dismissal = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
