import {
    URLAPI,
    EMAIL_CREATE_PATH,
    EMAIL_UPDATE_PATH,
    EMAIL_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _create_email: {
        data: {},
        status: 0,
        message: {},
    },
    _update_email: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_email: {
        data: {},
        status: 0,
        message: {},
    },
};

export const CREATE_EMAIL = "CREATE_EMAIL";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const DELETE_EMAIL = "DELETE_EMAIL";

export const email_module = (state = init, action) => {
    switch (action.type) {
        case CREATE_EMAIL:
            return {
                ...state,
                _create_email: action.payload,
            };
        case UPDATE_EMAIL:
            return {
                ...state,
                _update_email: action.payload,
            };
        case DELETE_EMAIL:
            return {
                ...state,
                _delete_email: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


//create Email
export const createEmail = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${EMAIL_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_EMAIL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_EMAIL,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_EMAIL,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateEmail = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${EMAIL_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_EMAIL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_EMAIL,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_EMAIL,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const deleteEmail = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${EMAIL_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_EMAIL,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_EMAIL,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_EMAIL,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_email = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
