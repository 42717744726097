import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Modal from "../../../components/modal";
import {toast} from "react-toastify";
import {clear_suppliers, deleteSupplier, DELETE_SUPPLIER, getAllSuppliers} from "../../../redux/supplier";

const Supplier = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const supplier = useSelector(
        (state) => state.supplier_module._get_all_suppliers
    );
    const [data, setData] = useState([]);
    useEffect(() => {
        if (supplier.data.datos) {
            setData(supplier.data.datos);
        }
    }, [supplier]);
    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_supplier').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/proveedores/editar/${item.id}`);
                    },
                })

            }
            if (permisos.filter(x => x.codigo === 'delete_supplier').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);

    const _delete_supplier = useSelector(
        (state) => state.supplier_module._delete_supplier
    );

    useEffect(() => {
        if (_delete_supplier.status === 200) {
            toast.success(_delete_supplier.data);
            dispatch(clear_suppliers(DELETE_SUPPLIER));
            history.push("/proveedores");
            dispatch(getAllSuppliers(token));
            setModal(false);
            setId(null);
        } else if (_delete_supplier.status !== 0) {
            dispatch(clear_suppliers(DELETE_SUPPLIER));
            toast.error(_delete_supplier.message);
        }
    }, [_delete_supplier, history, token, dispatch]);

    const handleDelete = () => {
        dispatch(deleteSupplier(token, id));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Proveedores"
                            items={[{label: "Proveedores", link: "/proveedores"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_supplier').length > 0 ? <Link
                                        to="/proveedores/crear"
                                        className="btn btn-success"
                                    >
                                        Crear
                                    </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "ruc",
                                                label: "Ruc",
                                                type: "text",
                                            },
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "foto",
                                                label: "Imagen",
                                                type: "avatar",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            handleDelete()
                        }}>
                            SI
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            setModal(false)
                        }}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Supplier;
