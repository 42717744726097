import React, { useState, useRef, useEffect } from "react";
import Measure from "react-measure";
import { useUserMedia } from "../../hooks/use-user-media";
import { useCardRatio } from "../../hooks/use-card-ratio";
import { useOffsets } from "../../hooks/use-offsets";
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
} from "./styles";
import CameraButton from './camera-button'
import * as faceapi from 'face-api.js';
import ButtonClear from './button-clear'

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "user" }
};

export function Camera({ onCapture, onClear,onCheck,onErrorCapture}) {
  const canvasRef = useRef();
  const videoRef = useRef();
  const canvasRefMask = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  const [detectionScores, setDetectionScores] = useState([]);

  useEffect(() => {
    videoRef && loadModels();
    // eslint-disable-next-line
  }, []);

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('/models'),
    ]).then(() => {
      faceDetection();
    })
  };


  const faceDetection = async () => {
    setInterval(async () => {




      try{
        const detections = await faceapi.detectAllFaces(videoRef.current,
          new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();



          if(detections.length > 0){
            // eslint-disable-next-line
            detections.map((detection) =>{
              setDetectionScores([...detectionScores, detection.detection.score])
              faceapi.matchDimensions(canvasRefMask.current, {
                width: videoRef.current.videoWidth,
                height: videoRef.current.videoHeight,
              })
              let resized = null;
              //verificar si es un dispositivo movil
              if(window.innerWidth < 768){
                resized = faceapi.resizeResults(detection, {
                  width: window.innerWidth+50,
                  height: videoRef.current.videoHeight-200,
                })
              }else{
                resized = faceapi.resizeResults(detections, {
                  width: videoRef.current.videoWidth,
                  height: videoRef.current.videoHeight,
                });
              }


              // to draw the detection onto the detected face i.e the box
              faceapi.draw.drawDetections(canvasRefMask.current, resized);
              faceapi.draw.drawFaceExpressions(canvasRefMask.current, resized);
              faceapi.draw.drawFaceLandmarks(canvasRefMask.current, resized);
            }
            );
      }else{
        setDetectionScores([])

      }
    }catch(e){
    }

    }, 100)
  }

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio)
    });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  function handleCapture() {

    if(detectionScores.length > 0){
      const max = detectionScores.reduce((a, b) => Math.max(a, b));
      if(max > 0.5){

        const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );

    canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
    setIsCanvasEmpty(false);
    setIsFlashing(true);


      }else{
        onErrorCapture("Coloque su rostro mirando la cámara")
      }
    }else{
      onErrorCapture("Coloque su rostro mirando la cámara")

    }
  }

  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            ref={measureRef}
            maxHeight={videoRef.current && videoRef.current.videoHeight}
            maxWidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              height: `${container.height}px`
            }}
          >
            <Video
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted

              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`,
                height:'auto'
              }}
            />
            <canvas ref={canvasRefMask}
              className="app__canvas"

            />

            <Overlay hidden={!isVideoPlaying} />

            <Canvas
              ref={canvasRef}
              width={container.width}
              height={container.height}
            />

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
            {isVideoPlaying && (
            <>
            {
              isCanvasEmpty?<CameraButton onClick={handleCapture}/>
              : <div className="">
                <ButtonClear onClick={onCheck} icon="bx bx-check" theme="success col-1"
                style={{
                  marginLeft:'10%'
                }}
                />
              <ButtonClear onClick={handleClear} icon="bx bx-x" theme="danger col-1"
             style={{
              marginRight:'10%'
            }}
              /></div>
            }

            {isCanvasEmpty ? "Tomar foto" : "Tomar una nueva foto"}


            </>

          )}
          </Container>


        </Wrapper>
      )}
    </Measure>
  );
}
