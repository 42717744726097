import React from "react";
// import './button-clear.css'

const ButtonClear = ({ onClick,icon,theme,style }) => {
    return (
        <button className={`btn btn-${theme} button-clear `} onClick={onClick}
        style={style}>

            <i className={icon}></i>
        </button>
    )
}

export default ButtonClear;
