import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    createDepartureReason,
    clearDeparture,
    CREATE_DEPARTURE_REASON,
    getAllDeparturesReasons,
} from "../../../redux/departures";
import LoadingSpinner from "../../../components/loading-spinner";
import Checkbox from "../../../components/check-box";

/** @module  Pages/departure_reasons/CreateReason */

/**
 * Te devuelve un formulario que te permite crear una razón de salida
 * @returns {JSX.Element}
 *
 * @example
 * <CreateReason />
 */

const CreateReason = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {_create_departure_reason} = useSelector((state) => state.departures_module);
    const {token} =
    useSelector((state) => state.users_module.login.data) || null;
    const [form, setForm] = useState({
        id_tipo: "",
        descripcion: "",
        salida_sin_aprobacion: false,
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_create_departure_reason.status === 201) {
            toast.success("Creado correctamente");
            dispatch(clearDeparture(CREATE_DEPARTURE_REASON));
            setTimeout(() => {
                history.push("/razones");
                dispatch(getAllDeparturesReasons(token));
            }, 1000); // 2-second delay
            setIsLoading(false);
        } else if (_create_departure_reason.status !== 0) {
            if (_create_departure_reason.message) {
                toast.error(_create_departure_reason.message);
                dispatch(clearDeparture(CREATE_DEPARTURE_REASON));
                setIsLoading(false);
            }
        }
    }, [_create_departure_reason, history, dispatch, token]);

    // useEffect(() => {
    //   console.log(form);
    // }, [form]);

    const handleChange = (e) => {
        if (e.target.name === "salida_sin_aprobacion") {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.id_tipo === "") {
            toast.error("Debe seleccionar el tipo");
            setIsLoading(false);
            return;
        }
        if (form.descripcion === "") {
            toast.error("Debe ingresar la descripción");
            setIsLoading(false);
            return;
        }
        dispatch(createDepartureReason(token, form));
    };

    return (
        <div className='app-content content'>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className='content-overlay'/>

            <div className='content-wrapper'>
                <div className='content-header row'>
                    <div className='content-header-left col-12 mb-2 mt-1'>
                        <Breadcrumbs
                            title='Razones'
                            items={[{label: "Razones", link: "/razones"}]}
                        />
                    </div>
                </div>
                <div className='content-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <form className='card' onSubmit={handleSubmit}>
                                <div className='card-header'>
                                    <h4 className='card-title'>Crear Razón</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>tipo de salida</label>
                                            <select
                                                className='form-control'
                                                onChange={handleChange}
                                                id='id_tipo'
                                                name='id_tipo'
                                            >
                                                <option value='' hidden>Seleccionar tipo</option>
                                                <option value='1'>PROGRAMADA</option>
                                                <option value='2'>EMERGENCIA</option>
                                                <option value='3'>RECURRENTE</option>
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <Input
                                                id='descripcion'
                                                name='descripcion'
                                                label='Descripción'
                                                value={form.descripcion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <Checkbox
                                                id="salida_sin_aprobacion"
                                                label="Salida sin aprobación"
                                                name="salida_sin_aprobacion"
                                                onChange={handleChange}
                                                state={form.salida_sin_aprobacion}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer d-flex justify-content-end'>
                                    <Link to='/razones' className='btn btn-danger m-1'>
                                        Cancelar
                                    </Link>

                                    <Button
                                        type='submit'
                                        className='btn btn-success'
                                        onClick={() => {
                                        }}
                                        text='Crear'
                                        theme='success'
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateReason;
