import React from 'react';


const CardItem = ({children,...props}) => {
    return (
        <div className="card "
            {...props}
       >
            <div className="card-content">
                <div className="card-body" style={{
                    boxShadow: "0px 0px 10px #888888",
                }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CardItem;