import React, { useEffect, Fragment, useState } from 'react';

import './face-detection.css';

import { Camera } from "./camera";
import { Root, Footer, GlobalStyle } from "./styles";
// import {capture_photo,CAPTURE_PHOTO} from '../redux/employees';


const FaceDetection = ({handleFile,handleSubmit,handleError,open}) => {

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  // eslint-disable-next-line
  const [cardImage, setCardImage] = useState();


  useEffect(() => {
    setIsCameraOpen(open);
  }, [open]);



  return (
    <Fragment>
      <Root style={{
         width: '100vw',
         height: '100vh',
      }}>
        {isCameraOpen && (
          <Camera
            onCapture={blob => {
              handleFile(blob)
              setCardImage(blob)
            }}
            onClear={() =>{
              handleFile(undefined)
              setCardImage(undefined)
            }}
            onCheck={() =>{
              handleSubmit()
            }
            }
            onErrorCapture={(e) =>{
              handleError(e)
            }}

          />
        )}



        <Footer>
          <button className='btn btn-success'
            onClick={() => setIsCameraOpen(true)}>Open Camera</button>
          <button
            className='btn btn-success'
            onClick={() => {
              setIsCameraOpen(false);
              setCardImage(undefined);
            }}
          >
            Close Camera
          </button>
        </Footer>
      </Root>
      <GlobalStyle />
    </Fragment>
  );
}
export default FaceDetection;


