import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import Card from "../../../components/card";
import CheckBox from "../../../components/check-box";
import Input from "../../../components/input";
import ItemList1 from "../../../components/item-list-1";
import Search from "../../../components/search";
import SelectInput from "../../../components/select";
import {get_status} from "../../../config";
import {
    updateIncome,
    clearIncome,
    UPDATE_INCOME,
    getAllIncome,
    get_report_income,
    GET_REPORT_INCOME
} from '../../../redux/income';
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";

const IncomeStores = (props) => {
    const dispatch = useDispatch();

    /* Usando el gancho useSelector para obtener el estado del módulo de ingresos. */
    const _get_all_income = useSelector((state) => state.income_module._get_all_income);

    const _get_report_income = useSelector((store) => store.income_module._get_report_income);

    const {token} = useSelector((state) => state.users_module.login.data) || null;


    // const _get_all_establishments = useSelector((state) => state.establishment_module._get_all_establishments)

    const [showDetail, setShowDetail] = useState(false);
    const [showSibarLeft, setShowSibarLeft] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    // const [report, setReport] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    /* Creating an array of objects. */
    const filter_options = [
        {name: "HOY", id: 1},
        {name: "AYER", id: 2},
        {name: "ÚLTIMOS 7 DÍAS", id: 3},
        {name: "ÚLTIMOS 30 DÍAS", id: 4},
        {name: 'Rango de fechas', id: 5}
    ];


    const [range_date, setRangeDate] = useState({
        visible: false,
        date_start: '',
        date_end: ''
    })

    const [datas, setDatas] = useState([]);
    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);
    const [n_page, setNPage] = useState(0);

    const dataSelectedInit = {
        id: 0,
        fecha_creacion: "",
        titulo: "",
        descripcion: "",
        observacion: "",
        usuario_crea: "",
        visita_programada: true,
        fecha_visita: "",
        fecha_solicitud_aprobacion: "",
        fecha_respuesta_aprobacion: "",
        establecimiento: "",
        estado: "",
        empleados: [],
        visitantes: [],
        proveedores: []
    }
    const [DataSelected, setDataSelected] = useState(dataSelectedInit);

    // eslint-disable-next-line
    const initForm = {
        titulo: "",
        descripcion: "",
        observacion: "",
        usuario_crea: "",
        id_establecimiento: 0,
        visita_programada: true,
        fecha: "",
        visitantes: [],
        establecimiento: {id: 0, name: "Seleccione una opcion"}
    }
    // eslint-disable-next-line
    const [form, setForm] = useState(initForm)

    const [permisos, setPermisos] = useState([]);

    // eslint-disable-next-line
    const [filtros, setFiltros] = useState({
        creadas: false,
        pendiente_aprobar: false,
        ingreso_aprobado: false,
        ingreso_requerido: false,
        ingreso_aceptado: false,
    });


    // useEffect(() => {
    //     setReport(_get_report_income.status)
    // }, [_get_report_income])

    useEffect(() => {
        if (props) {
            //converto to array
            const array = Object.values(props);
            setPermisos(array);
            console.log(array);
        }
        if (_get_all_income.data.datos) {
            setDatas(_get_all_income.data.datos);
            setNPage(_get_all_income.data.n_page);
        }

    }, [_get_all_income, count, props]);


    const loadFunc = () => {
        if (_get_all_income.data.datos) {
            if (_get_all_income.data.datos.length > count) {
                const ordenado = _get_all_income.data.datos.sort((a, b) => {
                    return new Date(b.fecha + ' 00:00:00') - new Date(a.fecha + ' 00:00:00');
                });
                setDatas([...datas, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }
            setHasMore(false);
        }
    };

    const _update_income = useSelector((state) => state.income_module._update_income);

    useEffect(() => {
        if (_update_income.status === 200) {
            getAllIncome(
                token,
                userEstablishmentsList.establecimientos?.map((par) => par.id),
                1
            );
            dispatch(clearIncome(UPDATE_INCOME));
            setIsLoading(false);
        } else if (_update_income.status !== 0) {
            toast.error(_update_income.message);
            dispatch(clearIncome(UPDATE_INCOME));
            setIsLoading(false);
        }
    }, [_update_income, dispatch, token, userEstablishmentsList]);


    const handleClickAprove = (type) => {
        setIsLoading(true);
        let data = {estado: type}
        dispatch(updateIncome(token, DataSelected.id, data));
    }

    const filterToday = (e) => {
        setRangeDate({
            date_end: '',
            date_start: '',
            visible: false
        })

        let values = [];
        let today = new Date();
        values = datas;

        let actual = []
        if (filtros.pendiente_aprobar || filtros.ingreso_aprobado || filtros.creadas || filtros.ingreso_requerido || filtros.ingreso_aceptado) {
            if (filtros.pendiente_aprobar) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "PENDIENTE APROBAR"));
            }
            if (filtros.ingreso_aprobado) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO APROBADO"));
            }
            if (filtros.creadas) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "CREADO"));
            }
            if (filtros.ingreso_requerido) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO REQUERIDO"));
            }
            if (filtros.ingreso_aceptado) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO ACEPTADO"));
            }
        } else {
            actual = _get_all_income.data.datos;
        }

        if (e.target.value === "0") {
            setDatas(actual)
        }

        //HOY
        if (e.target.value === "1") {
            // eslint-disable-next-line
            values = actual.filter((item) => {
                if (item.fecha) {
                    let fecha = new Date(item.fecha + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return item;
                    }
                }
            })
            setDatas(values);
            return;
        }

        //AYER
        if (e.target.value === "2") {
            let today = new Date();
            today.setDate(today.getDate() - 1)
            // eslint-disable-next-line
            values = actual.filter((item) => {
                if (item.fecha) {
                    let fecha = new Date(item.fecha + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return item;
                    }
                }
            })
            setDatas(values);
            return;
        }

        //ÚLTIMOS 7 DÍAS
        if (e.target.value === "3") {
            let today = new Date();

            today.setDate(today.getDate() - 7)
            // eslint-disable-next-line
            values = actual.filter((item) => {
                if (item.fecha) {
                    let fecha = new Date(item.fecha + ' 00:00:00');
                    if (fecha.getTime() <= new Date().getTime() && fecha.getTime() >= today.getTime()) {
                        return item;
                    }
                }
            })
            setDatas(values);
            return;
        }

        //ÚLTIMOS 30 DÍAS
        if (e.target.value === "4") {
            let today = new Date();
            today.setDate(today.getDate() - 30)
            // eslint-disable-next-line
            values = actual.filter((item) => {
                if (item.fecha) {
                    let fecha = new Date(item.fecha + ' 00:00:00');
                    if (fecha.getTime() >= today.getTime() && fecha.getTime() <= new Date().getTime()) {
                        return item;
                    }
                }
            })
            setDatas(values);
            return;
        }

        if (e.target.value === '5') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: true
            })
        }
    }

    const onItemClickListener = (item) => {
        setDataSelected(item);
        setShowDetail(true);
    }

    const handleFilterState = (e, type) => {
        if (type === "CREADO") {
            filtros.creadas = e.target.checked
        }
        if (type === "PENDIENTE APROBAR") {
            filtros.pendiente_aprobar = e.target.checked
        }

        if (type === "INGRESO APROBADO") {
            filtros.ingreso_aprobado = e.target.checked
        }
        if (type === "INGRESO REQUERIDO") {
            filtros.ingreso_requerido = e.target.checked
        }
        if (type === "INGRESO ACEPTADO") {
            filtros.ingreso_aceptado = e.target.checked
        }

        let actual = []

        if (filtros.pendiente_aprobar && type !== "PENDIENTE APROBAR") {
            actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "PENDIENTE APROBAR"));
        }
        if (filtros.ingreso_aprobado && type !== "INGRESO APROBADO") {
            actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO APROBADO"));
        }
        if (filtros.creadas && type !== "CREADO") {
            actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "CREADO"));
        }
        if (filtros.ingreso_requerido && type !== "INGRESO REQUERIDO") {
            actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO REQUERIDO"));
        }
        if (filtros.ingreso_aceptado && type !== "INGRESO ACEPTADO") {
            actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO ACEPTADO"));
        }

        if (e.target.checked) {
            actual.concat();
            let select = _get_all_income.data.datos.filter(x => x.estado === type);
            actual = actual.concat(select);
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.fecha_visita) - new Date(a.fecha_visita);
            // });
            setDatas(actual)
        } else {
            if (!filtros.creadas && !filtros.pendiente_aprobar && !filtros.ingreso_aprobado && !filtros.ingreso_requerido && !filtros.ingreso_aceptado) {
                actual = _get_all_income.data.datos;
            } else {
                actual.filter(x => x.estado === type).pop()
            }
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.fecha_visita) - new Date(a.fecha_visita);
            // });
            setDatas(actual)
        }
    }

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setDatas(_get_all_income.data.datos);
            return;
        }
        setDatas([]);
        let nombre = e.target.value;
        nombre = nombre.trim();
        let re = new RegExp(nombre, "i");
        // eslint-disable-next-line
        let filtro = _get_all_income.data.datos.filter(element => {
            if (element.establecimiento.match(re) != null) {
                return element;
            }
        })
        setDatas(filtro);
    }

    const handleFilterRangeDate = (e) => {
        if (e.target.name === 'date_start') {
            range_date.date_start = e.target.value
        }
        if (e.target.name === 'date_end') {
            range_date.date_end = e.target.value
        }

        let actual = []
        if (filtros.pendiente_aprobar || filtros.ingreso_aprobado || filtros.creadas || filtros.ingreso_requerido || filtros.ingreso_aceptado) {
            if (filtros.pendiente_aprobar) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "PENDIENTE APROBAR"));
            }
            if (filtros.ingreso_aprobado) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO APROBADO"));
            }
            if (filtros.creadas) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "CREADO"));
            }
            if (filtros.ingreso_requerido) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO REQUERIDO"));
            }
            if (filtros.ingreso_aceptado) {
                actual = actual.concat(_get_all_income.data.datos.filter(x => x.estado === "INGRESO ACEPTADO"));
            }
        } else {
            actual = _get_all_income.data.datos;
        }


        if (range_date.date_start !== '' && range_date.date_end !== '') {
            let date_start = new Date(range_date.date_start + ' 00:00:00');
            let date_end = new Date(range_date.date_end + ' 00:00:00');
            // eslint-disable-next-line
            let final = actual.filter(x => {
                    if (x.fecha) {
                        let fecha = new Date(x.fecha + ' 00:00:00');
                        if (fecha.getTime() >= date_start.getTime() && fecha.getTime() <= date_end.getTime()) {
                            return x;
                        }
                    }
                }
            )
            setDatas(final)
        }
    }

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        getAllIncome(
            token,
            userEstablishmentsList.establecimientos?.map((par) => par.id),
            count
        )
    }

    function less_data() {
        let count = page - 1;
        setPage(count)
        getAllIncome(
            token,
            userEstablishmentsList.establecimientos?.map((par) => par.id),
            count
        )
    }

    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (_get_report_income.status === 200) {
            toast.success(_get_report_income.data.mensaje);
            dispatch(clearIncome(GET_REPORT_INCOME));
            setModal(false);
            setIsLoading(false);
        } else if (_get_report_income.status !== 0) {
            toast.error(_get_report_income.message);
            dispatch(clearIncome(GET_REPORT_INCOME));
            setModal(false);
            setIsLoading(false);
        }
    }, [_get_report_income, dispatch]);

    const handleGenerateReport = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial");
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final");
            return;
        }

        // setReport(false);
        setModal(false);
        dispatch(get_report_income({'fecha_inicio': fecha_inicial, 'fecha_fin': fecha_final}, token))
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">

                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 ">
                        <Breadcrumbs
                            title="Ingresos"
                            items={[{label: "Listado ingresos", link: "/"}]}
                            onclick
                        />
                    </div>
                </div>
                <div className="content-body">

                    <div className="content-area-wrapper" style={{margin: 0}}>


                        <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                            <div className="sidebar">

                                <div className="sidebar-content email-app-sidebar d-flex">
                                    {/* sidebar close icon */}
                                    <span className="sidebar-close-icon" onClick={
                                        () => {
                                            setShowSibarLeft(false)
                                            setShowOverlay(false)
                                        }
                                    }>
                                        <i className="bx bx-x"/>
                                    </span>
                                    {/* sidebar close icon */}
                                    <div className="email-app-menu">
                                        <div className="sidebar-menu-list mt-1">
                                            {/* sidebar menu  */}
                                            <div className="list-group list-group-messages">
                                                <SelectInput
                                                    id="filtro-visita"
                                                    name="filtro-visita"
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className="list-unstyled mb-0">

                                                    {
                                                        range_date.visible ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Inicio"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'

                                                                />
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Fin"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'

                                                                />
                                                            </li>
                                                            <hr/>
                                                        </> : null
                                                    }


                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="CREADAS"
                                                            name="check-creadas"
                                                            id="check-creadas"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "CREADO");
                                                            }}
                                                            state={filtros.creadas}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="PENDIENTE APROBAR"
                                                            name="check-pendiente-aprobar"
                                                            id="check-pendiente-aprobar"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "PENDIENTE APROBAR");
                                                            }}
                                                            state={filtros.pendiente_aprobar}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="INGRESO APROBADO"
                                                            name="check-ingreso-aprobado"
                                                            id="check-ingreso-aprobado"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO APROBADO");
                                                            }}
                                                            state={filtros.ingreso_aprobado}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="INGRESO REQUERIDO"
                                                            name="check-ingreso-requerido"
                                                            id="check-ingreso-requerido"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO REQUERIDO");
                                                            }}
                                                            state={filtros.ingreso_requerido}
                                                        />
                                                    </li>
                                                    <li className=" mr-2 mb-1">
                                                        <CheckBox
                                                            label="INGRESO ACEPTADO"
                                                            name="check-ingreso-aceptado"
                                                            id="check-ingreso-aceptado"
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO ACEPTADO");
                                                            }}
                                                            state={filtros.ingreso_aceptado}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* sidebar label end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="content-overlay"/>
                            <div className="content-wrapper">
                                <div className="content-header row"/>
                                <div className="content-body">
                                    {/* email app overlay */}
                                    <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                                         onClick={
                                             () => {
                                                 setShowSibarRight(!showSibarRight);
                                                 setShowSibarLeft(false);
                                                 setShowDetail(false);
                                                 setShowOverlay(!showOverlay);
                                             }
                                         }
                                    />
                                    <div className="email-app-area">
                                        {/* Email list Area */}
                                        <div className="email-app-list-wrapper">
                                            <div className="email-app-list">
                                                <div className="email-action">
                                                    {/* action left start here */}

                                                    {/* action left end here *-/}

                      {/* action right start here */}
                                                    <div
                                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                        {/* search bar  */}
                                                        <div className="email-fixed-search flex-grow-1">
                                                            <div className="sidebar-toggle d-block d-lg-none" onClick={
                                                                () => {
                                                                    setShowSibarLeft(!showSibarLeft);
                                                                    setShowOverlay(!showOverlay);
                                                                }
                                                            }>
                                                                <i className="bx bx-menu"/>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <Search placeholder={"Buscar por establecimiento"}
                                                                            onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Button
                                                                        text={<h3><i
                                                                            className="text-white bx bx-export"/></h3>}
                                                                        theme="success"
                                                                        type="button"
                                                                        classes="btn-lg round"
                                                                        onClick={() => {
                                                                            setModal(true);
                                                                            setFechaInicial('');
                                                                            setFechaFinal('');
                                                                        }}
                                                                    />
                                                                    {/* {!report ? <Button text={
                                                                        <h3><i className="text-white bx bx-export"/>
                                                                        </h3>
                                                                    } theme="success" type="button"
                                                                                       classes="btn-lg round"
                                                                                       onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                                                    /> : <div class="spinner-border text-success"
                                                                              role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* pagination and page count */}
                                                    </div>
                                                </div>
                                                {/* / action right */}

                                                <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                                    <div className="col-4">
                                                        <label className="ml-1">Página {page}</label>
                                                    </div>
                                                    <div className="col-8 text-right">
                                                        {(page > 1 && page <= n_page) &&
                                                            <button className="btn btn-primary mr-1" type="button"
                                                                    onClick={() => {
                                                                        less_data();
                                                                    }}><i class="bx bx-arrow-back"></i></button>
                                                        }
                                                        {(page >= 1 && page < n_page) &&
                                                            <button className="btn btn-success" type="button"
                                                                    onClick={() => {
                                                                        more_data();
                                                                    }}><i class="bx bx-right-arrow-alt"></i></button>
                                                        }
                                                    </div>
                                                </div>

                                                {/* email user list start */}
                                                <div className="email-user-list list-group">
                                                    <ul className="users-list-wrapper media-list" style={{
                                                        overflowY: 'scroll',
                                                    }}>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => loadFunc()}
                                                            hasMore={HasMore}
                                                            useWindow={false}

                                                            loader={
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <div
                                                                        className="spinner-border text-success"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            }
                                                        >
                                                            {datas.map((item, index) => (
                                                                <ItemList1
                                                                    item={item}
                                                                    title={item.establecimiento}
                                                                    subtitle={item.fecha}
                                                                    rightDescription={item.estado}
                                                                    bulletType={get_status(item.estado)}
                                                                    /* children={
                                                                        <div className="user-details div_fecha">
                                                                            {(item.fecha_solicitud_aprobacion) &&
                                                                                <div className="mail-items">
                                                                                    <span>Fecha de solicitud de aprobación: </span>{item.fecha_solicitud_aprobacion}
                                                                                </div>
                                                                            }
                                                                            {(item.fecha_respuesta_aprobacion) &&
                                                                                <div className="mail-meta-item">
                                                                                    <span>Fecha de aprobación: </span>{item.fecha_respuesta_aprobacion}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    } */
                                                                    onClick={onItemClickListener}
                                                                />
                                                            ))}
                                                        </InfiniteScroll>
                                                    </ul>
                                                    {/* email user list end */}

                                                    {/* no result when nothing to show on list */}
                                                    <div className="no-results">
                                                        <i className="bx bx-error-circle font-large-2"/>
                                                        <h5>No Items Found</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Email list Area */}

                                        {/* Detailed Email View */}
                                        <div className={`email-app-details ${showDetail ? 'show' : ''} `}>
                                            {/* email detail view header */}
                                            <div className="email-detail-header">
                                                <div className="email-header-left d-flex align-items-center mb-1">
                                                    <span className="go-back mr-50" onClick={
                                                        () => {
                                                            setShowDetail(false);
                                                        }
                                                    }>
                                                        <span className="fonticon-wrap d-inline">
                                                        <i className="bx bx-arrow-back"/>
                                                        </span>
                                                    </span>
                                                    <h5 className="email-detail-title font-weight-normal mb-0">
                                                        {DataSelected.establecimiento}
                                                    </h5>
                                                </div>
                                                <div className="email-header-right mb-1 ml-2 pl-1">
                                                    <span
                                                        className={`badge badge-light-${get_status(DataSelected.estado)} badge-pill ml-1`}>
                                                        {DataSelected.estado}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* email detail view header end*/}
                                            <div className="email-scroll-area" style={{overflowY: 'scroll',}}>
                                                {/* email details  */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="collapsible email-detail-head">
                                                            <Card
                                                                title={DataSelected.establecimiento}
                                                                description={
                                                                    <>
                                                                        <span>Fehca de apertura: </span>{DataSelected.fecha_apertura}
                                                                        <br/>
                                                                    </>
                                                                }
                                                                subtitle={DataSelected.fecha}
                                                            >
                                                                <form className="row">
                                                                    {(DataSelected.fecha_aprobacion) &&
                                                                        <div className="col-12 mb-1">
                                                                            <h6>Fecha de
                                                                                aprobación:</h6>{DataSelected.fecha_aprobacion}
                                                                        </div>
                                                                    }
                                                                    {(DataSelected.fecha_solicitud_apertura) &&
                                                                        <div className="col-12 mb-1">
                                                                            <h6>Fecha de solicitud de
                                                                                apertura:</h6>{DataSelected.fecha_solicitud_apertura}
                                                                        </div>
                                                                    }
                                                                    {(DataSelected.fecha_respuesta_apertura) &&
                                                                        <div className="col-12 mb-1">
                                                                            <h6>Fecha de respuesta de
                                                                                apertura:</h6>{DataSelected.fecha_respuesta_apertura}
                                                                        </div>
                                                                    }
                                                                    <div className="col-12 div-flex mt-2 mb-5">
                                                                        {/* {
                                                                            permisos.filter(item => item.codigo === "approve_income").length > 0 && DataSelected.estado === "INGRESO REQUERIDO" ? (
                                                                                <Button
                                                                                    text="Aprobar"
                                                                                    onClick={
                                                                                        () => {
                                                                                            handleClickAprove("INGRESO ACEPTADO");
                                                                                        }
                                                                                    }
                                                                                    theme="success ml-2"
                                                                                    type="submit"
                                                                                />
                                                                            ) : null
                                                                        } */}
                                                                        {
                                                                            permisos.filter(item => item.codigo === "response_income").length > 0 && DataSelected.estado === "PENDIENTE APROBAR" ? (
                                                                                <Button
                                                                                    text="Aprobar ingreso"
                                                                                    onClick={
                                                                                        () => {
                                                                                            handleClickAprove("INGRESO APROBADO");
                                                                                        }
                                                                                    }
                                                                                    theme="success"
                                                                                    classes="button_div1"
                                                                                    type="submit"
                                                                                    disabled={isLoading}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </form>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* email details  end*/}
                                                <div className="row px-2 mb-4">
                                                    {/* quill editor for reply message */}
                                                    <div className="col-12 px-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Detailed Email View */}
                                        {/* <VisitantModal item={visitantmodal}/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleGenerateReport}>
                    <div className="card-body">
                        <h5 className="mb-2">Exportar datos</h5>
                        <div className="form-group">
                            <Input
                                type="date"
                                label="Fecha Inicio"
                                onChange={(e) => {
                                    setFechaInicial(e.target.value)
                                }}
                                name='fecha_inicial'
                                value={fecha_inicial}
                            />
                            <Input
                                type="date"
                                label="Fecha Fin"
                                onChange={(e) => {
                                    setFechaFinal(e.target.value)
                                }}
                                name='fecha_final'
                                value={fecha_final}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-success mb-1 button_div`} disabled={isLoading}>Exportar</button>
                        <button type="button" onClick={() => {
                            setFechaInicial('');
                            setFechaFinal('');
                            setModal(false);
                        }} className={`btn btn-outline-danger button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default IncomeStores;
