import React, {useState, useEffect} from "react";
import Input from "../../../components/input";
import TextArea from "../../../components/text-area";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../components/button";
import {
    createEmergencyDeparture,
    createScheduledDeparture,
    // createDeparture,
    getAllDeparturesReasons,
    getAllDeparturesReasonsTypes,
    clearDeparture,
    CREATE_SCHEDULED_DEPARTURE,
    CREATE_EMERGENCY_DEPARTURE,
} from "../../../redux/departures";
import {getAllEmployees} from "../../../redux/employees";
import {ToastContainer, toast} from "react-toastify";
import "./departure.css";
import LoadingSpinner from "../../../components/loading-spinner";

const CreateUpdateModal = (props) => {
    //initial declarations
    const {open, setOpen} = props;
    const dispatch = useDispatch();
    const init = {
        id_razon: "",
        detalle: "",
        id_establecimiento: 0,
        fecha: "",
        id_empleado: "",
        dias_ausencia: "0",
    };

    const [isLoading, setIsLoading] = useState(false);

    //data fetching and redux state handler
    const {token} =
    useSelector((state) => state.users_module.login.data) || null;
    const _get_all_departures_reasons = useSelector(
        (state) => state.departures_module._get_all_departures_reasons.data.datos
    );
    const _get_all_employees = useSelector(
        (state) => state.employees_module._get_all_employees.data.datos
    );
    const _get_establishment_user = useSelector(
        (state) => state.users_module._session_data.data.establecimientos
    );

    //States in the component
    const [form, setForm] = useState(init);
    const [type, setType] = useState(true);
    const [establecimientos, setEstablecimientos] = useState();

    // This will log the updated state of form but you can hcange it to log any calue you want to see
    // useEffect(() => {
    //   console.log(form);
    // }, [form]); //

    //useEffect:
    useEffect(() => {
        dispatch(getAllDeparturesReasons(token));
        dispatch(getAllDeparturesReasonsTypes(token));
        dispatch(getAllEmployees(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_establishment_user) {
            const establishmentNames = _get_establishment_user.map(
                (establishment) => establishment.nombre
            );
            const establishmentIds = _get_establishment_user.map(
                (establishment) => establishment.id
            );
            setEstablecimientos(establishmentNames);
            setForm({
                ...form,
                id_establecimiento: establishmentIds.toString(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_get_establishment_user]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleTypoChange = (e) => {
        if (e.target.value === "1") {
            setType(false);
            setForm({
                ...form,
                fecha: minToday(),
                dias_ausencia: "0",
            });
        } else if (e.target.value === "2") {
            setType(true);
            setForm({
                ...form,
                fecha: "",
            });
        } else {
            setType(true);
            setForm({
                ...form,
                fecha: "",
            });
        }
    };

    const _create_scheduled_departure = useSelector((state) => state.departures_module._create_scheduled_departure);
    const _create_emergency_departure = useSelector((state) => state.departures_module._create_emergency_departure);

    useEffect(() => {
        if (_create_scheduled_departure.status === 201) {
            setForm(init);
            setOpen(false);
            dispatch(clearDeparture(CREATE_SCHEDULED_DEPARTURE));
            setIsLoading(false);
        } else {
            if (_create_scheduled_departure.status !== 0) {
                toast.error(_create_scheduled_departure.message);
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [_create_scheduled_departure, init]);

    useEffect(() => {
        if (_create_emergency_departure.status === 201) {
            setForm(init);
            setOpen(false);
            dispatch(clearDeparture(CREATE_EMERGENCY_DEPARTURE));
            setIsLoading(false);
        } else {
            if (_create_emergency_departure.status !== 0) {
                toast.error(_create_emergency_departure.message);
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [_create_emergency_departure, init]);

    const handleCreateScheduledDeparture = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(createScheduledDeparture(token, form));
    };

    const handleCreateEmergencyDeparture = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(createEmergencyDeparture(token, form));
    };

    //Other function that can´t be declared in arrow function
    function minToday() {
        const today = new Date();
        today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
        return today.toISOString().slice(0, 10);
    }

    function minTomorrow() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 2);
        tomorrow.setMinutes(tomorrow.getMinutes() - tomorrow.getTimezoneOffset());
        return tomorrow.toISOString().slice(0, 10);
    }

    return (
        <div className={`modal fade text-left ${open ? 'show' : ''}`} id="inlineForm" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel33" aria-hidden={open ? 'true' : 'false'}
             style={{
                 display: open ? 'block' : ''
             }}
        >
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className='modal-content'>
                    {isLoading && <LoadingSpinner/>}
                    <ToastContainer/>
                    <div className='modal-header'>
                        <h4 className='text-bold-400'>Crear Salida </h4>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setOpen(false)}
                        >
                            <i className='bx bx-x'/>
                        </button>
                    </div>
                    <form
                        style={{
                            overflowY: "scroll",
                        }}
                    >
                        <div className='modal-body container'>
                            <div className='col-md-14'>
                                <label>tipo</label>
                                <select
                                    className='form-control'
                                    label='Tipo'
                                    name='tipo'
                                    required
                                    onChange={handleTypoChange}
                                >
                                    <option value={0} hidden>
                                        Seleccione un tipo
                                    </option>
                                    <option value={1}>Salidas Operacionales</option>
                                    <option value={2}>Salida programada</option>
                                </select>
                                <p></p>
                                <label>motivo</label>
                                {type === true ? (<select
                                    className='form-control'
                                    name='id_razon'
                                    required
                                    onChange={handleChange}
                                >
                                    <option value={0}>
                                        Seleccione un motivo
                                    </option>
                                    {_get_all_departures_reasons !== undefined ? (
                                        _get_all_departures_reasons.filter((data) =>
                                            data.tipo.includes("PROGRAMADA") && data.activo === true
                                        ).map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.descripcion}
                                            </option>
                                        ))
                                    ) : (
                                        <h5>No hay motivos disponibles</h5>
                                    )}
                                </select>) : (
                                    <select
                                        className='form-control'
                                        name='id_razon'
                                        required={true}
                                        onChange={handleChange}
                                    >
                                        <option value={0} hidden>
                                            Seleccione un motivo
                                        </option>
                                        {_get_all_departures_reasons !== undefined ? (
                                            _get_all_departures_reasons.filter((data) =>
                                                data.tipo.includes("EMERGENCIA") && data.activo === true
                                            ).map((item, index) => (
                                                <option key={index + 1} value={item.id}>
                                                    {item.descripcion}
                                                </option>
                                            ))
                                        ) : (
                                            <h5>No hay motivos disponobles</h5>
                                        )}
                                    </select>)}
                                <p></p>
                                <TextArea
                                    label='Detalle'
                                    name='detalle'
                                    id='detalle'
                                    onChange={handleChange}
                                    required
                                    rows={5}
                                    placeholder='Motivo de la salida'
                                />
                                {type === true ? (
                                    <Input
                                        label='fecha'
                                        name='fecha'
                                        type='date'
                                        required={true}
                                        min={minTomorrow()}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <h5>fecha emergencia: {minToday()}</h5>
                                )}
                                {type === true ? (
                                    <Input
                                        label='Dias de ausencia'
                                        name='dias_ausencia'
                                        // placeholder='Días de ausencia'
                                        required={true}
                                        value={form.dias_ausencia}
                                        type='number'
                                        min='0'
                                        // max="200"
                                        onChange={handleChange}
                                        onKeyPress={(e) =>
                                            !/[0-9]/.test(e.key) && e.preventDefault()
                                        }
                                    />
                                ) : (
                                    <Input
                                        placeholder='No aplica'
                                        type='text'
                                        disabled
                                        value=''
                                    />
                                )}
                                <p></p>
                                <label>Empleado</label>
                                <select
                                    className='form-control'
                                    name='id_empleado'
                                    required={true}
                                    onChange={handleChange}
                                >
                                    <option value={0} hidden>
                                        Seleccione un empleado
                                    </option>
                                    {_get_all_employees !== undefined ? (
                                        _get_all_employees
                                            .filter((data) =>
                                                data.establecimiento.includes(establecimientos)
                                            )
                                            .map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.nombres}
                                                </option>
                                            ))
                                    ) : (
                                        <option>No reasons available</option>
                                    )}
                                </select>
                                <p></p>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            {type === true ? (
                                <Button
                                    className='button-d'
                                    type='submit'
                                    text='Guardar'
                                    theme='success'
                                    onClick={handleCreateScheduledDeparture}
                                    disabled={isLoading}
                                />
                            ) : (
                                <Button
                                    className='button-d'
                                    type='submit'
                                    text='Guardar'
                                    theme='success'
                                    onClick={handleCreateEmergencyDeparture}
                                    disabled={isLoading}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateUpdateModal;
