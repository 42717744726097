import React from 'react';


const ItemChat = ({ image, title, subtitle, status = "" }) => {
    return (
        <li style={{
            listStyle: "none",
            marginTop: "10px",
        }}>
            <div className="d-flex align-items-center">
                <div className="avatar m-0 mr-50">
                    
                    <img src={image} height="36" width="36" alt={title} />
                    {
                        status !== "" &&
                        <span className={`avatar-status-${status}`}></span>

                    }
                </div>
                <div className="chat-sidebar-name">
                    <h6 className="mb-0">
                        {title}
                    </h6><span className="text-muted">{subtitle}</span>
                </div>
            </div>
        </li>
    )
}

export default ItemChat;