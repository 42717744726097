import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import Dropzone from '../../../components/dropzone';
import Button from '../../../components/button'
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs";
import {
    IMPORT_EMPLOYEE,
    clear_employee,
    importEmployee,
    getAllEmployees,
    get_status_task
} from '../../../redux/employees';
import DataTable from "../../../components/data-table";
import {URL_FILE_UPLOAD_EMPLOYEE} from '../../../config'
import {getAllJobs} from "../../../redux/jobs";

const Import = () => {
    let dispatch = useDispatch();

    const _import_employee = useSelector((store) => store.employees_module._import_employee);
    const _general_settings = useSelector((store) => store.base_module._general_settings_all)
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [progress, setProgress] = useState(0);
    let timer = null;

    const [result, setResult] = useState([])

    const [load, setLoad] = useState(false);
    const [file, setFile] = useState(null);
    const [settings, setSettings] = useState([])
    const [url_template, setUrlTemplate] = useState(null)


    useEffect(() => {
        if (_general_settings.data) {
            setSettings(_general_settings.data)
        }
    }, [_general_settings.data])

    useEffect(() => {
        let datos = settings.filter(x => x.key === URL_FILE_UPLOAD_EMPLOYEE)
        if (datos.length !== 0) {
            setUrlTemplate(datos[0].value)
        }
    }, [settings])

    useEffect(() => {
        if (_import_employee.status === 200) {
            setLoad(true);

            if (_import_employee.data) {
                if (timer == null) {
                    status_task(_import_employee.data);
                }

            }
            dispatch(getAllEmployees(token));
            dispatch(getAllJobs(token));
            dispatch(clear_employee(IMPORT_EMPLOYEE));
        } else if (_import_employee.status !== 0) {
            setLoad(false)

            toast.error(_import_employee.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            dispatch(clear_employee(IMPORT_EMPLOYEE))

        }
        // eslint-disable-next-line
    }, [_import_employee, dispatch, token])


    const clearTimer = (timer) => {
        clearInterval(timer);
        timer = null;
        console.log(timer)
        dispatch(clear_employee(IMPORT_EMPLOYEE))
    };

    const status_task = (data) => {
        timer = setInterval(() => {
            get_status_task(data.task_id, token)
                .then((res) => {
                    console.log(res)
                    if (res.status === 202) {
                        setProgress(res.data.data.meta.result.current);
                    } else if (res.status === 200) {
                        setProgress(100);
                        setResult(res.data.data.meta.result.datos)
                        clearTimer(timer);
                    }
                })
                .catch((err) => {

                    console.log(err);
                    setLoad(false);
                    setProgress(0);
                    clearTimer(
                        timer
                    );
                });
        }, 5000);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        let form = new FormData();
        form.append("archivo_excel", file);
        setLoad(true);
        dispatch(importEmployee(token, form))
    }

    const handleFile = (e) => {
        setFile(e.target.files[0]);
    }


    const handleDownloadTemplateFile = () => {
        var link = document.createElement("a");
        link.download = "Carga_empleados.xlsx";
        link.href = url_template
        link.click();

    }


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[{label: "Empleados", link: "/empleados"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <section>
                                    <div className="row flexbox-container">
                                        <div className="col-12 text-center p-3">
                                            <h1 className="error-title mb-2">Importar empleados</h1>
                                            <p className="mb-1">
                                                se admite archivo excel
                                            </p>
                                            {
                                                url_template ? <Button onChange={handleDownloadTemplateFile}
                                                                       text="Descargar plantilla" theme="success"/> : ""
                                            }

                                            <div id="clockFlat"
                                                 className="card-text text-center coming-soon pt-2 d-flex justify-content-center mb-2"/>
                                            <form onSubmit={handleSubmit}>
                                                <fieldset className="form-group position-relative w-75 mx-auto">
                                                    <Dropzone id="foto" name="foto" handleChange={
                                                        (e) => {
                                                            handleFile(e)
                                                        }
                                                    }
                                                              state={file}
                                                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    />
                                                    <div className="row">
                                                        <div className="col-12">

                                                            {
                                                                load ? <div class="progress">
                                                                        <div class="progress-bar bg-success"
                                                                             role="progressbar"
                                                                             style={{width: progress + '%'}}
                                                                             aria-valuenow="25" aria-valuemin="0"
                                                                             aria-valuemax="100">{progress}%
                                                                        </div>
                                                                    </div>
                                                                    : <Button classes="cs-btn-notify round mt-3"
                                                                              theme="primary" type="submit"
                                                                              text="Enviar"/>
                                                            }

                                                        </div>
                                                        {
                                                            result.length !== 0 ? <div className="table-responsive">
                                                                <DataTable
                                                                    dataTable={result}
                                                                    columns={[
                                                                        {
                                                                            name: "fila",
                                                                            label: "Fila",
                                                                            type: "text",
                                                                        },
                                                                        {
                                                                            name: "creado",
                                                                            label: "Creado",
                                                                            type: "boolean",
                                                                        },
                                                                        {
                                                                            name: "mensaje",
                                                                            label: "Mensaje",
                                                                            type: "text",
                                                                        },

                                                                    ]}
                                                                    actions={null}
                                                                />
                                                            </div> : null
                                                        }
                                                        <div className="col-12">
                                                            <Link to="/empleados"
                                                                  className=" round btn btn-danger mt-1">
                                                                Cancelar
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Import;
