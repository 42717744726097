import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "../../../components/select";
import SelectInput from "../../../components/select";
import CheckBox from "../../../components/check-box";
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    clear_employee,
    updateEmployee,
    UPDATE_EMPLOYEE,
    getAllEmployees,
    getLogsEmployees,
} from "../../../redux/employees";
import {URLAPI} from "../../../config/index";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../../components/loading-spinner";

const UpdateEmployee = (props) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    /* A state variable that is used to store the form data. */
    const [form, setForm] = useState({
        ruc: "",
        nombres: "",
        apellidos: "",
        id_cargo: 0,
        cargo: "",
        id_empresa: 0,
        id_establecimiento: 0,
        empresa: "",
        establecimiento: "",
        id_banco: 0,
        banco: "",
        numero_cuenta: "",
        tipo_cuenta: "",
        tipo_jornada: "",
        imagen: "",
        correo: "",
        telefono: "",
        fecha_ingreso: "",
        fecha_salida: "",
        motivo: "",
        activo: true,
        motivo_despido: "",
        id_motivo_despido: 0,
        correos_adicionales: []
    });

    const [isLoading, setIsLoading] = useState(false);

    const [banks, setBanks] = useState([]);

    let history = useHistory();
    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _get_all_reason_dismissal = useSelector(
        (state) => state.reason_dismissal_module._get_all_reason_dismissal
    );

    const _establishment = useSelector(
        (state) => state.establishment_module._get_all_establishments
    );

    const _update_employee = useSelector(
        (state) => state.employees_module._update_employee
    );

    const _get_all_employees = useSelector(
        (state) => state.employees_module._get_all_employees
    );

    const _get_all_bank = useSelector(
        (state) => state.bank_module._get_all_bank
    );

    const _jobs = useSelector((state) => state.jobs_module._get_all_jobs);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [companies, setCompanies] = useState([]);
    const [reason_dismissal, setReasonDismissal] = useState([]);
    const [establishments, setEstablishments] = useState([]);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        if (_companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_get_all_reason_dismissal.data.datos) {
            let fiter = _get_all_reason_dismissal.data.datos.filter(x => x.activo === true);
            setReasonDismissal(fiter);
        }
    }, [_get_all_reason_dismissal]);

    useEffect(() => {
        if (_establishment.data.datos) {
            setEstablishments(_establishment.data.datos);
        }
    }, [_establishment]);

    useEffect(() => {
        if (_get_all_employees.data) {
            // eslint-disable-next-line
            _get_all_employees.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm(item);
                    let v_establishment = _establishment.data.datos.filter(x => item.id_empresa === x.id_empresa);
                    setEstablishments(v_establishment);
                }
            });
        }
        // eslint-disable-next-line
    }, [_get_all_employees, id]);

    /* A state variable that is used to store the form data. */
    useEffect(() => {
        if (_update_employee.status === 200) {
            toast.success("El empleado ha sido actualizado correctamente")
            dispatch(clear_employee(UPDATE_EMPLOYEE));
            history.push("/empleados");
            dispatch(getAllEmployees(token));
            setIsLoading(false);
            dispatch(getLogsEmployees(token, '', 1));

        } else {
            if (_update_employee.status !== 0) {
                toast.error(_update_employee.message);
                setIsLoading(false);
            }
        }
    }, [_update_employee, token, dispatch, history]);

    useEffect(() => {
    }, []);

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (_jobs.data) {
            let cargos = _jobs.data.datos.filter(x => x.activo === true)
            setJobs(cargos);
        }
    }, [_jobs]);

    useEffect(() => {
        if (_get_all_bank && _get_all_bank.data && _get_all_bank.data.datos) {
            let filter = _get_all_bank.data.datos.filter(x => x.activo === true);
            setBanks(filter);
        }
    }, [_get_all_bank]);

    const handleChange = (e) => {
        if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "id_cargo" || e.target.name === "id_empresa" || e.target.name === "id_establecimiento" || e.target.name === "id_banco" || e.target.name === "id_motivo_despido") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
            if (e.target.name === "id_empresa") {
                document.getElementById("id_establecimiento").selectedIndex = 0;
                let v_establishment = _establishment.data.datos.filter(x => parseInt(e.target.value) === parseInt(x.id_empresa));
                setEstablishments(v_establishment);
            }
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeEmail = (e) => {
        let correos = form.correos_adicionales;
        // eslint-disable-next-line
        correos.map(c => {
            if (c.label === e.target.name) {
                c.value = e.target.value
            }
        })

        setForm({
            ...form,
            correos_adicionales: correos
        })
    }

    const [count, setCount] = useState(1);

    function add_email() {
        const c = "Correo" + count;
        const correos = form.correos_adicionales;
        correos.push({'label': c, 'value': ''});
        setForm({
            ...form,
            correos_adicionales: correos,
        });
        setCount(count + 1);
    }

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.ruc === "") {
            toast.error("Debe ingresar el ruc");
            setIsLoading(false);
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido");
            setIsLoading(false);
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo electrónico");
            setIsLoading(false);
            return;
        }
        if (form.imagen === "") {
            toast.error("La imagen es requerida");
            setIsLoading(false);
            return;
        }
        if (form.id_cargo === 0) {
            toast.error("Debe seleccionar el cargo");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            setIsLoading(false);
            return;
        }
        if (form.id_establecimiento === 0) {
            toast.error("Debe seleccionar el establecimiento");
            setIsLoading(false);
            return;
        }
        if (form.tipo_jornada === "") {
            toast.error("Debe seleccionar tipo de jornada");
            setIsLoading(false);
            return;
        }
        if (form.activo === false) {
            if (form.fecha_salida === null) {
                toast.error("Debe ingresar la fecha de salida");
                setIsLoading(false);
                return;
            }
            if (form.id_motivo_despido === 0) {
                toast.error("Debe seleccionar el motivo de salida");
                setIsLoading(false);
                return;
            }
        }
        dispatch(updateEmployee(token, id, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[
                                {label: "Empleados", link: "/empleados"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Empleado</h4>
                                </div>
                                <div className="card-body">
                                    <div className=" row">
                                        <div className="col-12">
                                            <h5>Información Adicional</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Ruc"
                                                name="ruc"
                                                onChange={handleChange}
                                                value={form.ruc}
                                                readonly="readonly"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombres"
                                                onChange={handleChange}
                                                value={form.nombres}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                onChange={handleChange}
                                                value={form.apellidos}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                onChange={handleChange}
                                                value={form.correo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Telefono"
                                                name="telefono"
                                                onChange={handleChange}
                                                value={form.telefono}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Imagen"
                                                name="imagen"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="col-md-8 text-center">
                                            <img
                                                src={
                                                    imagePreview == null
                                                        ? `${URLAPI}${form.foto}`
                                                        : imagePreview
                                                }
                                                alt="imagen"
                                                width={60}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h5>Información del cargo</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Cargo</label>

                                                <Select
                                                    label="Cargo"
                                                    name="id_cargo"
                                                    value={form.id_cargo}
                                                    select={form.cargo}
                                                    options={jobs.map((job) => ({
                                                        name: job.nombre,
                                                        id: job.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>
                                                <Select
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    value={form.id_empresa}
                                                    select={form.empresa}
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Establecimiento</label>
                                                <Select
                                                    id="id_establecimiento"
                                                    label="Establecimiento"
                                                    name="id_establecimiento"
                                                    value={form.id_establecimiento}
                                                    select={form.establecimiento}
                                                    options={establishments.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Tipo de jornada</label>
                                            <select
                                                className='form-control'
                                                name='tipo_jornada'
                                                required={true}
                                                onChange={handleChange}
                                            >
                                                <option value={0} hidden>Seleccione un tipo de jornada</option>
                                                <option value="complete"
                                                        selected={form.tipo_jornada === 'complete'}>Completa
                                                </option>
                                                <option value="partial"
                                                        selected={form.tipo_jornada === 'partial'}>Parcial
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                type="date"
                                                label="Fecha Ingreso"
                                                name="fecha_ingreso"
                                                onChange={handleChange}
                                                value={form.fecha_ingreso}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {permisos.filter(x => x.codigo === 'nomina').length > 0 &&
                                            <>
                                                <div className="col-12">
                                                    <h5>Información bancaria</h5>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Banco</label>
                                                        <SelectInput
                                                            label="Banco"
                                                            name="id_banco"
                                                            options={banks.map((item) => ({
                                                                name: item.nombre,
                                                                id: item.id,
                                                            }))}
                                                            onChange={handleChange}
                                                            select={form.banco}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Tipo de cuenta</label>
                                                    <select
                                                        className='form-control'
                                                        name='tipo_cuenta'
                                                        required={true}
                                                        onChange={handleChange}
                                                    >
                                                        <option value={0} hidden>Seleccione un tipo de cuenta</option>
                                                        <option value="saving"
                                                                selected={form.tipo_cuenta === 'saving'}>Ahorro
                                                        </option>
                                                        <option value="current"
                                                                selected={form.tipo_cuenta === 'current'}>Corriente
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <Input
                                                        label="Número de cuenta"
                                                        name="numero_cuenta"
                                                        onChange={handleChange}
                                                        value={form.numero_cuenta}
                                                    />
                                                </div>
                                            </>
                                        }

                                        <div className="col-4">
                                            <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}
                                                      state={form.activo}/>
                                        </div>
                                        {(form.activo === false) &&
                                            <div className="col-md-4">
                                                <Input
                                                    type="date"
                                                    label="Fecha salida"
                                                    name="fecha_salida"
                                                    onChange={handleChange}
                                                    value={form.fecha_salida}
                                                />
                                            </div>
                                        }
                                        {(form.activo === false) &&
                                            <div className="col-md-4">
                                                <label>Motivo</label>
                                                <SelectInput
                                                    label="Motivo"
                                                    name="id_motivo_despido"
                                                    options={reason_dismissal.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    select={form.motivo_despido}
                                                />
                                                {/*<select*/}
                                                {/*    className='form-control'*/}
                                                {/*    name='motivo'*/}
                                                {/*    required={true}*/}
                                                {/*    onChange={handleChange}*/}
                                                {/*>*/}
                                                {/*    <option value={0} hidden>Seleccione un motivo</option>*/}
                                                {/*    <option value="dismissal" selected={form.motivo === 'dismissal'}>Despido</option>*/}
                                                {/*    <option value="resignation" selected={form.motivo === 'resignation'}>Renuncia</option>*/}
                                                {/*    <option value="probation" selected={form.motivo === 'probation'}>Periodo de prueba</option>*/}
                                                {/*</select>*/}
                                            </div>
                                        }
                                        {(form.activo === false) &&
                                            <>
                                                <div className="col-12 mt-2">
                                                    <div className="d-flex">
                                                        <h5 className="mt-1">Coreos adicionales</h5>
                                                        <Button type="button" onClick={() => {
                                                            add_email()
                                                        }} text="Agregar" theme="btn btn-success ml-2" classes="m-1"/>
                                                    </div>
                                                </div>
                                                {form.correos_adicionales.length > 0 &&
                                                    form.correos_adicionales.map(ca =>
                                                        <div className="col-md-4">
                                                            <Input
                                                                label={ca.label}
                                                                name={ca.label}
                                                                onChange={handleChangeEmail}
                                                                value={ca.value}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/empleados" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Guardar"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                        onClick={handleSubmit}
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateEmployee;
