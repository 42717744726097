import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {get_status} from "../../../config";
import Card from "../../../components/card";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import {toast, ToastContainer} from 'react-toastify';
import {
    getAllIncome,
    request_opening,
    request_approval,
    clearIncome,
    REQUEST_OPENING,
    REQUEST_APPROVAL
} from '../../../redux/income';
import LoadingSpinner from "../../../components/loading-spinner";
import Modal from "../../../components/modal";

const IncomeDetails = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _get_all_income = useSelector((state) => state.income_module._get_all_income);
    const _request_opening = useSelector((state) => state.income_module._request_opening);
    const _request_approval = useSelector((state) => state.income_module._request_approval);

    const [isLoading, setIsLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    const [DataSelected, setDataSelected] = useState(
        {
            id: 0,
            fecha: "",
            fecha_apertura: "",
            fecha_maxima_apertura: "",
            establecimiento: "",
            estado: "",
        }
    );

    useEffect(() => {
        if (_get_all_income.data.datos) {
            if (_get_all_income.data.datos.length > 0) {
                setDataSelected(_get_all_income.data.datos.find(income => income.id === parseInt(id)));
            }
        }
    }, [_get_all_income, id, DataSelected])

    useEffect(() => {
        if (_request_opening.status === 200) {
            dispatch(clearIncome(REQUEST_OPENING));
            //toast.success(_request_opening.message);
            dispatch(getAllIncome(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            setIsLoading(false);
        } else if (_request_opening.status !== 0) {
            toast.error(_request_opening.message);
            dispatch(clearIncome(REQUEST_OPENING));
            setIsLoading(false);
        }
    }, [_request_opening, dispatch, token, userEstablishmentsList]);

    useEffect(() => {
        if (_request_approval.status === 200) {
            dispatch(clearIncome(REQUEST_APPROVAL));
            dispatch(getAllIncome(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            setIsLoading(false);
            if (_request_approval.data.pendiente === true) {
                setModalInfo(true);
            }
        } else if (_request_approval.status !== 0) {
            toast.error(_request_approval.message);
            dispatch(clearIncome(REQUEST_APPROVAL));
            setIsLoading(false);
        }
    }, [_request_approval, dispatch, token, userEstablishmentsList]);

    const handleAperture = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(request_opening(token, id, {}));
    }

    const handleAprove = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(request_approval(token, id, {}));
        /*     setDataSelected({...DataSelected, estado: "EN ESPERA"});
            let data = {estado: "EN ESPERA"}
            dispatch(updateVisit(token, DataSelected.id, data)); */
    }


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">

                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Ingreso tienda"
                            items={[{label: "Ingreso tienda", link: "/ingreso-tienda"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="app-content-overlay"/>
                    <div className="email-app-area">
                        <div className="email-app-list-wrapper">
                            <div className="email-app-list">
                                <div className="email-action">
                                    <div
                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="email-app-details" style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#DFE3E7",
                        }}>
                            <div className="navbar navbar-light bg-white p-2"
                                 style={{
                                     borderBottom: "1px solid #DFE3E",
                                 }}
                            >

                                <div className="email-header-left d-flex align-items-center mb-1">
                                    <Link to="/ingreso-tienda" className="go-back mr-50">
                                        <span className="fonticon-wrap d-inline">
                                            <i className="bx bx-arrow-back"/>
                                        </span>
                                    </Link>
                                    <h5 className="email-detail-title font-weight-normal mb-0">
                                        {DataSelected.establecimiento}
                                    </h5>
                                </div>
                                <div className="email-header-right mb-1 ml-2 pl-1">
                                    <span
                                        className={`badge badge-light-${get_status(DataSelected.estado)} badge-pill ml-1`}>
                                        {DataSelected.estado}
                                    </span>
                                </div>
                            </div>
                            <div className="email-scroll-area">
                                <div className="row">
                                    <div className="col-12 p-3">
                                        <div className="collapsible email-detail-head">
                                            <Card
                                                title={DataSelected.establecimiento}
                                                description={
                                                    <>
                                                        <span>Fecha: </span><strong>{new Date(DataSelected.fecha + " 00:00:00").toLocaleDateString("es-ES", {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })}</strong><br/>
                                                        <span>Fecha de apertura: </span><strong>{DataSelected.fecha_apertura}</strong>
                                                    </>
                                                }
                                                subtitle={
                                                    DataSelected.estado === "CREADO" ? (
                                                        <div className="col-12 d-flex justify-content-end">
                                                            <Button
                                                                text="Solicitar"
                                                                onClick={handleAprove}
                                                                theme="success"
                                                                type="button"
                                                                disabled={isLoading}
                                                            />
                                                        </div>
                                                    ) : (DataSelected.estado === "INGRESO APROBADO") ? (
                                                        <div className='col-12 d-flex justify-content-end'>
                                                            <Button
                                                                text='Solicitar apertura'
                                                                onChange={handleAperture}
                                                                theme='success'
                                                                type='button'
                                                                disabled={isLoading}
                                                            />
                                                        </div>
                                                    ) : null
                                                }
                                            >
                                                <form className="row">
                                                    {(DataSelected.fecha_aprobacion) &&
                                                        <div className="col-12 mb-1">
                                                            <h6>Fecha de aprobación:</h6>{DataSelected.fecha_aprobacion}
                                                        </div>
                                                    }
                                                    {(DataSelected.fecha_solicitud_apertura) &&
                                                        <div className="col-12 mb-1">
                                                            <h6>Fecha de solicitud de
                                                                apertura:</h6>{DataSelected.fecha_solicitud_apertura}
                                                        </div>
                                                    }
                                                    {(DataSelected.fecha_respuesta_apertura) &&
                                                        <div className="col-12 mb-1">
                                                            <h6>Fecha de respuesta de
                                                                apertura:</h6>{DataSelected.fecha_respuesta_apertura}
                                                        </div>
                                                    }
                                                </form>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-2 mb-4">
                                    <div className="col-12 px-0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main1 div-modal" show={modalInfo}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>Se ha excedido el tiempo límite de ingreso a la joyería, su solicitud debe ser aprobada
                                por el jefe zonal antes de solicitar la apertura a Monitoreo</h5>
                        </div>
                    </div>
                    <div className='text-center mb-1'>
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            setModalInfo(false)
                        }}>
                            Ok
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default IncomeDetails;
