import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import CheckBox from "../../../components/check-box";
import Input from "../../../components/input";
import ItemList2 from "../../../components/item-list-2";
import Search from "../../../components/search";
import SelectInput from "../../../components/select";
import {get_status} from "../../../config";
// import { useHistory } from "react-router-dom";
import DepartureDetailsModal from "./DetailsModal";
import VisitsDetailsModalMonitoring from "./visits-details-details-monitoring";
import IncomeModal from "../income/income-modal";
import {
    getAllDeparturesReasons,
    // getAllDepartures,
} from "../../../redux/departures";
import {getAllVisitsAndDepartures} from "../../../redux/monitoringDeparturesAndVisits"
import Modal from "../../../components/modal";
import {get_report_monitoring, clearVisit, GET_REPORT_MONITORING} from "../../../redux/visits";
import LoadingSpinner from "../../../components/loading-spinner";

const VisitasSalidasMonitoreo = (props) => {
    //React Hooks
    const dispatch = useDispatch();

    //Redux States
    const _get_all_visits_and_departures = useSelector(
        (state) => state.visits_and_departures_module._get_all_visits_and_departures
    );

    const _get_report_monitoring = useSelector((store) => store.visits_module._get_report_monitoring);

    // const userEstablishmentsList = useSelector(
    //   (state) => state.users_module._session_data.data
    // );

    const {token} =
    useSelector((state) => state.users_module.login.data) || null;

    /* useEffect(() => {
        setReport(_get_report_monitoring.status)
    }, [_get_report_monitoring]) */

    const [isLoading, setIsLoading] = useState(false);

    //Constants
    const initForm = {
        id_razon: "",
        detalle: "",
        id_establecimiento: "",
        dias_semana: [],
    };


    const filter_options = [
        /* { name: "HOY", id: 1 },
        { name: "AYER", id: 2 },
        { name: "ÚLTIMOS 7 DÍAS", id: 3 },
        { name: "ÚLTIMOS 30 DÍAS", id: 4 }, */
        {name: "Rango de fechas", id: 5},
    ];

    //useStates:
    // const [showDetail, setShowDetail] = useState(false);
    const [showSibarLeft, setShowSibarLeft] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    // const [report, setReport] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const [selectedRowId, setSelectedRowID] = useState("");
    const [departures, setDepartures] = useState([]);
    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);
    //const [permisos, setPermisos] = useState([]);
    const [n_page, setNPage] = useState(0);
    const [range_date, setRangeDate] = useState({
        visible: false,
        date_start: "",
        date_end: "",
    });
    // eslint-disable-next-line
    const [form, setForm] = useState(initForm);
    // eslint-disable-next-line
    const [filtros, setFiltros] = useState({
        creadas: false,
        salidas_aprobadas: false,
        denegadas: false,
        caducadas: false,
        pendiente_zonal: false,
        espera: false,
        salida_requerida: false,
        aprobadas: false,
        rechazadas: false,
        salida_denegada: false,
        salida_rechazada: false,
        aceptadas: false,
        retorno_requerido: false,
        retorno_aceptado: false,
        retorno_rechazado: false,
        pendiente_aprobar: false,
        ingreso_aprobado: false,
        ingreso_requerido: false,
        ingreso_aceptado: false,
        search: "",
    });

    //useEffects:
    useEffect(() => {
        dispatch(getAllDeparturesReasons(token));
    }, [token, dispatch]);


    useEffect(() => {
        if (props) {
            //converto to array
            /* const array = Object.values(props);
            setPermisos(array); */
        }
        if (_get_all_visits_and_departures.data.datos) {
            setDepartures(_get_all_visits_and_departures.data.datos);
            setNPage(_get_all_visits_and_departures.data.n_page);
        }
    }, [_get_all_visits_and_departures, count, props]);

    //function handlers
    function setOpen() {
        setOpenModal(!openModal);
    }

    function setOpen2() {
        setOpenModal2(!openModal2);
    }

    function setOpen3() {
        setOpenModal3(!openModal3);
    }

    const loadFunc = () => {
        if (_get_all_visits_and_departures.data.datos) {
            if (_get_all_visits_and_departures.data.datos.length > count) {
                const ordenado = _get_all_visits_and_departures.data.datos.sort(
                    (a, b) => {
                        return (
                            new Date(b.date + " 00:00:00") - new Date(a.date + " 00:00:00")
                        );
                    }
                );
                setDepartures([...departures, ...ordenado.slice(count, count + 10)]);
                setCount(count + 10);
            }

            setHasMore(false);
        }
    };
    const filterToday = (e) => {
        let salidas = [];
        let today = new Date();
        salidas = departures;

        let actual = [];
        if (
            filtros.salidas_aprobadas ||
            filtros.aprobadas ||
            filtros.rechazadas ||
            filtros.caducadas ||
            filtros.creadas ||
            filtros.espera ||
            filtros.pendiente_zonal ||
            filtros.salida_denegada ||
            filtros.salida_rechazada ||
            filtros.aceptadas ||
            filtros.retorno_aceptado ||
            filtros.retorno_rechazado ||
            filtros.retorno_requerido ||
            filtros.pendiente_aprobar ||
            filtros.ingreso_aprobado ||
            filtros.ingreso_requerido ||
            filtros.ingreso_aceptado ||
            filtros.search
        ) {
            if (filtros.salidas_aprobadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA APROBADA"
                    )
                );
            }
            if (filtros.aprobadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "APROBADO"
                    )
                );
            }
            if (filtros.rechazadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "ANULADO"
                    )
                );
            }
            if (filtros.caducadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "CADUCADO"
                    )
                );
            }
            if (filtros.creadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "CREADO"
                    )
                );
            }
            if (filtros.espera) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "EN ESPERA"
                    )
                );
            }
            if (filtros.pendiente_zonal) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "PENDIENTE VISITA ZONAL"
                    )
                );
            }
            if (filtros.salida_requerida) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA REQUERIDA"
                    )
                );
            }
            if (filtros.salida_denegada) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA DENEGADA"
                    )
                );
            }
            if (filtros.salida_rechazada) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA RECHAZADA"
                    )
                );
            }
            if (filtros.aceptadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA ACEPTADA"
                    )
                );
            }
            if (filtros.retorno_requerido) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "RETORNO REQUERIDO"
                    )
                );
            }
            if (filtros.retorno_aceptado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "RETORNO ACEPTADO"
                    )
                );
            }
            if (filtros.retorno_rechazado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "RETORNO RECHAZADO"
                    )
                );
            }
            if (filtros.pendiente_aprobar) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "PENDIENTE APROBAR"
                    )
                );
            }
            if (filtros.ingreso_aprobado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO APROBADO"
                    )
                );
            }
            if (filtros.ingreso_requerido) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO REQUERIDO"
                    )
                );
            }
            if (filtros.ingreso_aceptado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO ACEPTADO"
                    )
                );
            }
        } else {
            actual = _get_all_visits_and_departures.data.datos;
        }

        if (e.target.value === "0") {
            setDepartures(actual);
            setRangeDate({
                date_end: "",
                date_start: "",
                visible: false,
            });
        }

        //HOY
        if (e.target.value === "1") {
            // eslint-disable-next-line
            salidas = actual.filter((salida) => {
                if (salida.date) {
                    let fecha = new Date(salida.date + " 00:00:00");
                    if (
                        fecha.getDate() === today.getDate() &&
                        fecha.getMonth() === today.getMonth() &&
                        fecha.getFullYear() === today.getFullYear()
                    ) {
                        return salida;
                    }
                }
            });
            setDepartures(salidas);

            return;
        }

        //AYER
        if (e.target.value === "2") {
            let today = new Date();
            today.setDate(today.getDate() - 1);
            // eslint-disable-next-line
            salidas = actual.filter((salida) => {
                if (salida.date) {
                    let fecha = new Date(salida.date + " 00:00:00");
                    if (
                        fecha.getDate() === today.getDate() &&
                        fecha.getMonth() === today.getMonth() &&
                        fecha.getFullYear() === today.getFullYear()
                    ) {
                        return salida;
                    }
                }
            });
            setDepartures(salidas);

            return;
        }

        //ÚLTIMOS 7 DÍAS
        if (e.target.value === "3") {
            let today = new Date();

            today.setDate(today.getDate() - 7);
            // eslint-disable-next-line
            salidas = actual.filter((salida) => {
                if (salida.date) {
                    let fecha = new Date(salida.date + " 00:00:00");
                    if (
                        fecha.getTime() <= new Date().getTime() &&
                        fecha.getTime() >= today.getTime()
                    ) {
                        return salida;
                    }
                }
            });
            setDepartures(salidas);

            return;
        }

        //ÚLTIMOS 30 DÍAS
        if (e.target.value === "4") {
            let today = new Date();
            today.setDate(today.getDate() - 30);
            // eslint-disable-next-line
            salidas = actual.filter((salida) => {
                if (salida.date) {
                    let fecha = new Date(salida.date + " 00:00:00");
                    if (
                        fecha.getTime() >= today.getTime() &&
                        fecha.getTime() <= new Date().getTime()
                    ) {
                        return salida;
                    }
                }
            });
            setDepartures(salidas);

            return;
        }

        if (e.target.value === "5") {
            setRangeDate({
                date_end: "",
                date_start: "",
                visible: true,
            });
        }
    };


    const handleFilterState = (e, type) => {
        if (type === "CREADO") {
            filtros.creadas = e.target.checked;
        }
        if (type === "ANULADO") {
            filtros.rechazadas = e.target.checked;
        }
        if (type === "APROBADO") {
            filtros.aprobadas = e.target.checked;
        }
        if (type === "SALIDA APROBADA") {
            filtros.salidas_aprobadas = e.target.checked;
        }
        if (type === "CADUCADO") {
            filtros.caducadas = e.target.checked;
        }
        if (type === "EN ESPERA") {
            filtros.espera = e.target.checked;
        }
        if (type === "PENDIENTE VISITA ZONAL") {
            filtros.pendiente_zonal = e.target.checked;
        }
        if (type === "SALIDA REQUERIDA") {
            filtros.salida_requerida = e.target.checked;
        }
        if (type === "SALIDA DENEGADA") {
            filtros.salida_denegada = e.target.checked;
        }
        if (type === "SALIDA RECHAZADA") {
            filtros.salida_rechazada = e.target.checked;
        }
        if (type === "SALIDA ACEPTADA") {
            filtros.aceptadas = e.target.checked;
        }
        if (type === "RETORNO REQUERIDO") {
            filtros.retorno_requerido = e.target.checked;
        }
        if (type === "RETORNO ACEPTADO") {
            filtros.retorno_aceptado = e.target.checked;
        }
        if (type === "RETORNO RECHAZADO") {
            filtros.retorno_rechazado = e.target.checked;
        }
        if (type === "PENDIENTE APROBAR") {
            filtros.pendiente_aprobar = e.target.checked;
        }
        if (type === "INGRESO APROBADO") {
            filtros.ingreso_aprobado = e.target.checked;
        }
        if (type === "INGRESO REQUERIDO") {
            filtros.ingreso_requerido = e.target.checked;
        }
        if (type === "INGRESO ACEPTADO") {
            filtros.ingreso_aceptado = e.target.checked;
        }
        let actual = [];

        if (filtros.salidas_aprobadas && type !== "SALIDA APROBADA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "SALIDA APROBADA"
                )
            );
        }
        if (filtros.caducadas && type !== "CADUCADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "CADUCADO"
                )
            );
        }
        if (filtros.creadas && type !== "CREADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "CREADO"
                )
            );
        }
        if (filtros.espera && type !== "EN ESPERA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "EN ESPERA"
                )
            );
        }
        if (filtros.pendiente_zonal && type !== "PENDIENTE VISITA ZONAL") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "PENDIENTE VISITA ZONAL"
                )
            );
        }
        if (filtros.salida_requerida && type !== "SALIDA REQUERIDA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "SALIDA REQUERIDA"
                )
            );
        }
        if (filtros.salida_denegada && type !== "SALIDA DENEGADA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "SALIDA DENEGADA"
                )
            );
        }
        if (filtros.salida_rechazada && type !== "SALIDA RECHAZADA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "SALIDA RECHAZADA"
                )
            );
        }
        if (filtros.aceptadas && type !== "SALIDA ACEPTADA") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "SALIDA ACEPTADA"
                )
            );
        }
        if (filtros.retorno_requerido && type !== "RETORNO REQUERIDO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "RETORNO REQUERIDO"
                )
            );
        }
        if (filtros.retorno_rechazado && type !== "RETORNO RECHAZADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "RETORNO RECHAZADO"
                )
            );
        }
        if (filtros.pendiente_aprobar && type !== "PENDIENTE APROBAR") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "PENDIENTE APROBAR"
                )
            );
        }
        if (filtros.ingreso_aprobado && type !== "INGRESO APROBADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "INGRESO APROBADO"
                )
            );
        }
        if (filtros.ingreso_requerido && type !== "INGRESO REQUERIDO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "INGRESO REQUERIDO"
                )
            );
        }
        if (filtros.ingreso_aceptado && type !== "INGRESO ACEPTADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "INGRESO ACEPTADO"
                )
            );
        }
        if (filtros.retorno_aceptado && type !== "RETORNO ACEPTADO") {
            actual = actual.concat(
                _get_all_visits_and_departures.data.datos.filter(
                    (x) => x.state === "RETORNO ACEPTADO"
                )
            );
        }

        if (e.target.checked) {
            actual.concat();
            let select = _get_all_visits_and_departures.data.datos.filter(
                (x) => x.state === type
            );
            actual = actual.concat(select);
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.date) - new Date(a.date);
            // });
            setDepartures(actual);
        } else {
            if (
                !filtros.salidas_aprobadas &&
                !filtros.caducadas &&
                !filtros.creadas &&
                !filtros.espera &&
                !filtros.pendiente_zonal &&
                !filtros.salida_denegada &&
                !filtros.salida_rechazada &&
                !filtros.salida_requerida &&
                !filtros.pendiente_aprobar &&
                !filtros.ingreso_aceptado &&
                !filtros.ingreso_aprobado &&
                !filtros.ingreso_requerido
            ) {
                actual = _get_all_visits_and_departures.data.datos;
            } else {
                actual.filter((x) => x.state === type).pop();
            }
            // const ordenado = actual.sort((a, b) => {
            //     return new Date(b.date) - new Date(a.date);
            // });
            setDepartures(actual);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setDepartures(_get_all_visits_and_departures.data.datos);
            return;
        }
        setDepartures([]);
        let nombre = e.target.value;
        nombre = nombre.trim();
        let re = new RegExp(nombre, "i");
        // eslint-disable-next-line
        let filtro = _get_all_visits_and_departures.data.datos.filter((element) => {
            if (element.establishment.match(re) != null) {
                return element;
            }
        });
        setDepartures(filtro);
    };

    const handleFilterRangeDate = (e) => {
        if (e.target.name === "date_start") {
            range_date.date_start = e.target.value;
        }
        if (e.target.name === "date_end") {
            range_date.date_end = e.target.value;
        }

        let actual = [];
        if (
            filtros.salidas_aprobadas ||
            filtros.caducadas ||
            filtros.creadas ||
            filtros.espera ||
            filtros.pendiente_zonal ||
            filtros.salida_denegada ||
            filtros.salida_rechazada ||
            filtros.pendiente_aprobar ||
            filtros.ingreso_aprobado ||
            filtros.ingreso_requerido ||
            filtros.ingreso_aceptado
        ) {
            if (filtros.salidas_aprobadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA APROBADA"
                    )
                );
            }
            if (filtros.caducadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "CADUCADO"
                    )
                );
            }
            if (filtros.creadas) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "CREADO"
                    )
                );
            }
            if (filtros.espera) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "EN ESPERA"
                    )
                );
            }
            if (filtros.pendiente_zonal) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "PENDIENTE VISITA ZONAL"
                    )
                );
            }
            if (filtros.salida_requerida) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA REQUERIDA"
                    )
                );
            }
            if (filtros.salida_denegada) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA DENEGADA"
                    )
                );
            }
            if (filtros.salida_rechazada) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "SALIDA RECHAZADA"
                    )
                );
            }
            if (filtros.pendiente_aprobar) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "PENDIENTE APROBAR"
                    )
                );
            }
            if (filtros.ingreso_aprobado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO APROBADO"
                    )
                );
            }
            if (filtros.ingreso_requerido) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO REQUERIDO"
                    )
                );
            }
            if (filtros.ingreso_aceptado) {
                actual = actual.concat(
                    _get_all_visits_and_departures.data.datos.filter(
                        (x) => x.state === "INGRESO ACEPTADO"
                    )
                );
            }
        } else {
            actual = _get_all_visits_and_departures.data.datos;
        }
        if (range_date.date_start !== "" && range_date.date_end !== "") {
            let date_start = new Date(range_date.date_start + " 00:00:00");
            let date_end = new Date(range_date.date_end + " 23:59:59");
            // eslint-disable-next-line
            let final = actual.filter((x) => {
                if (x.date_create) {
                    let fecha = new Date(x.date_create);
                    if (
                        fecha.getTime() >= date_start.getTime() &&
                        fecha.getTime() <= date_end.getTime()
                    ) {
                        return x;
                    }
                }
            });

            setDepartures(final);
        }
    };

    // const handleAprove = (e) => {
    //   e.preventDefault();
    // };

    // const handleGenerateReport = () => {
    //     let final = []
    //     departures.forEach(e => {
    //         final.push(e.id)
    //     })

    //     dispatch(get_report({'lista_salidas': final}, token))
    // }

    //html

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(getAllVisitsAndDepartures(token, count));
    }


    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(getAllVisitsAndDepartures(token, count));
    }

    const [fecha_inicial, setFechaInicial] = useState('');
    const [fecha_final, setFechaFinal] = useState('');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (_get_report_monitoring.status === 200) {
            toast.success(_get_report_monitoring.data.mensaje);
            dispatch(clearVisit(GET_REPORT_MONITORING));
            setModal(false);
            setIsLoading(false);
        } else if (_get_report_monitoring.status !== 0) {
            toast.error(_get_report_monitoring.message);
            dispatch(clearVisit(GET_REPORT_MONITORING));
            setModal(false);
            setIsLoading(false);
        }
    }, [_get_report_monitoring, dispatch])

    const handleGenerateReport = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (fecha_inicial === "") {
            toast.error("Debe seleccionar la fecha inicial");
            return;
        }
        if (fecha_final === "") {
            toast.error("Debe seleccionar la fecha final");
            return;
        }

        // setReport(false);
        setModal(false);
        dispatch(get_report_monitoring({'fecha_inicio': fecha_inicial, 'fecha_fin': fecha_final}, token))
    }

    return (
        <div className='app-content content'>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className='content-wrapper'>
                <div className='content-header row'>
                    <div className='content-header-left col-12 mb-2 '>
                        <Breadcrumbs
                            title='Salidas'
                            items={[{label: "Listado Monitoreo", link: "/"}]}
                            onclick
                        />
                    </div>
                </div>
                <div className='content-body'>
                    <div className='content-area-wrapper' style={{margin: 0}}>
                        <div className={`sidebar-left ${showSibarLeft ? "show" : ""}`}>
                            <div className='sidebar'>
                                <div className='sidebar-content email-app-sidebar d-flex mb-20'>
                                    {/* sidebar close icon */}
                                    <span
                                        className='sidebar-close-icon'
                                        onClick={() => {
                                            setShowSibarLeft(false);
                                            setShowOverlay(false);
                                        }}
                                    >
                    <i className='bx bx-x'/>
                  </span>
                                    {/* sidebar close icon */}
                                    <div className='email-app-menu'>
                                        <div className='form-group form-group-compose'>
                                            {/* compose button  */}
                                        </div>
                                        <div className='sidebar-menu-list'>
                                            {/* sidebar menu  */}
                                            <div className='list-group list-group-messages'>
                                                <SelectInput
                                                    id='filtro-salida'
                                                    name='filtro-salida'
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className='list-unstyled mb-5'>
                                                    {range_date.visible ? (
                                                        <>
                                                            <li className=' mr-2 mb-1'>
                                                                <Input
                                                                    type='date'
                                                                    label='Fecha Inicio'
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'
                                                                />
                                                                <Input
                                                                    type='date'
                                                                    label='Fecha Fin'
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'
                                                                />
                                                            </li>
                                                            <hr/>
                                                        </>
                                                    ) : null}

                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='CREADAS'
                                                            name='check-creadas'
                                                            id='check-creadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "CREADO");
                                                            }}
                                                            state={filtros.creadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='PENDIENTE VISITA ZONAL'
                                                            name='check-pendiente-zonal'
                                                            id='check-pendiente-zonal'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "PENDIENTE VISITA ZONAL");
                                                            }}
                                                            state={filtros.pendiente_zonal}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='VISITAS EN ESPERA'
                                                            name='check-espera'
                                                            id='check-espera'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "EN ESPERA");
                                                            }}
                                                            state={filtros.espera}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='VISITAS APROBADAS'
                                                            name='aprobadas'
                                                            id='aprobadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "APROBADO");
                                                            }}
                                                            state={filtros.aprobadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='VISITAS RECHAZADAS'
                                                            name='check-rechazadas'
                                                            id='check-rechazadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "ANULADO");
                                                            }}
                                                            state={filtros.rechazadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='CADUCADAS'
                                                            name='check-caducadas'
                                                            id='check-caducadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "CADUCADO");
                                                            }}
                                                            state={filtros.caducadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='SALIDAS REQUERIDAS'
                                                            name='check-salida_requerida'
                                                            id='check-salida_requerida'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "SALIDA REQUERIDA");
                                                            }}
                                                            state={filtros.salida_requerida}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='SALIDAS APROBADAS'
                                                            name='check-salidas-aprobadas'
                                                            id='check-salidas-aprobadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "SALIDA APROBADA");
                                                            }}
                                                            state={filtros.salidas_aprobadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='SALIDAS DENEGADAS'
                                                            name='check-salida_denegada'
                                                            id='check-salida_denegada'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "SALIDA DENEGADA");
                                                            }}
                                                            state={filtros.salida_denegada}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='SALIDAS RECHAZADAS'
                                                            name='check-salida_rechazada'
                                                            id='check-salida_rechazada'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "SALIDA RECHAZADA");
                                                            }}
                                                            state={filtros.salida_rechazada}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='SALIDAS ACEPTADAS'
                                                            name='check-aceptadas'
                                                            id='check-aceptadas'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "SALIDA ACEPTADA");
                                                            }}
                                                            state={filtros.aceptadas}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='RETORNO REQUERIDO'
                                                            name='check-retorno-requerido'
                                                            id='check-retorno-requerido'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "RETORNO REQUERIDO");
                                                            }}
                                                            state={filtros.retorno_requerido}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='RETORNO ACEPTADO'
                                                            name='check-retorno-aceptado'
                                                            id='check-retorno-aceptado'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "RETORNO ACEPTADO");
                                                            }}
                                                            state={filtros.retorno_aceptado}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='RETORNO RECHAZADO'
                                                            name='check-retorno-rechazado'
                                                            id='check-retorno-rechazado'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "RETORNO RECHAZADO");
                                                            }}
                                                            state={filtros.retorno_rechazado}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='PENDIENTE APROBAR'
                                                            name='check-pendiente-aprobar'
                                                            id='check-pendiente-aprobar'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "PENDIENTE APROBAR");
                                                            }}
                                                            state={filtros.pendiente_aprobar}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='INGRESO APROBADO'
                                                            name='check-ingreso-aprobado'
                                                            id='check-ingreso-aprobado'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO APROBADO");
                                                            }}
                                                            state={filtros.ingreso_aprobado}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='INGRESO REQUERIDO'
                                                            name='check-ingreso-requerido'
                                                            id='check-ingreso-requerido'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO REQUERIDO");
                                                            }}
                                                            state={filtros.ingreso_requerido}
                                                        />
                                                    </li>
                                                    <li className=' mr-2 mb-1'>
                                                        <CheckBox
                                                            label='INGRESO ACEPTADO'
                                                            name='check-ingreso-aceptado'
                                                            id='check-ingreso-aceptado'
                                                            onChange={(e) => {
                                                                handleFilterState(e, "INGRESO ACEPTADO");
                                                            }}
                                                            state={filtros.ingreso_aceptado}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* sidebar label end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content-right'>
                            <div className='content-overlay'/>
                            <div className='content-wrapper'>
                                <div className='content-header row'/>
                                <div className='content-body'>
                                    {/* email app overlay */}
                                    <div
                                        className={`app-content-overlay ${
                                            showOverlay ? "show" : ""
                                        }`} email-us
                                        onClick={() => {
                                            setShowSibarLeft(false);
                                            setShowOverlay(!showOverlay);
                                        }}
                                    />
                                    <div className='email-app-area'>
                                        <div className='email-app-list-wrapper'>
                                            <div className='email-app-list'>
                                                <div
                                                    className='email-action'
                                                    style={
                                                        openModal === true ? {display: "none"} : null
                                                    }
                                                >
                                                    <div
                                                        className='action-right d-flex flex-grow-1 align-items-center justify-content-around'>
                                                        <div
                                                            className='email-fixed-search flex-grow-1'
                                                            style={
                                                                openModal === true ? {display: "none"} : null
                                                            }
                                                        >
                                                            <div
                                                                className='sidebar-toggle d-block d-lg-none'
                                                                onClick={() => {
                                                                    setShowSibarLeft(!showSibarLeft);
                                                                    setShowOverlay(!showOverlay);
                                                                }}
                                                            >
                                                                <i className='bx bx-menu'/>
                                                            </div>
                                                            {" "}
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <Search placeholder={"Buscar por establecimiento"}
                                                                            onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <Button
                                                                        text={<h3><i
                                                                            className="text-white bx bx-export"/></h3>}
                                                                        theme="success"
                                                                        type="button"
                                                                        classes="btn-lg round"
                                                                        onClick={() => {
                                                                            setModal(true);
                                                                            setFechaInicial('');
                                                                            setFechaFinal('');
                                                                        }}
                                                                    />
                                                                    {/* {!report ? <Button text={
                                                                        <h3><i className="text-white bx bx-export"/>
                                                                        </h3>
                                                                    } theme="success" type="button"
                                                                                        classes="btn-lg round"
                                                                                        onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                                                    /> : <div class="spinner-border text-success"
                                                                              role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* pagination and page count */}
                                                    </div>
                                                </div>

                                                {openModal === true ? (
                                                    <DepartureDetailsModal
                                                        open={openModal}
                                                        setOpen={setOpen}
                                                        departure_id={selectedRowId}
                                                    />
                                                ) : null}
                                                {openModal2 === true ? (
                                                    <VisitsDetailsModalMonitoring
                                                        open={openModal2}
                                                        setOpen={setOpen2}
                                                        visit_id={selectedRowId}
                                                    />
                                                ) : null}
                                                {openModal3 === true ? (
                                                    <IncomeModal
                                                        open={openModal3}
                                                        setOpen={setOpen3}
                                                        id={selectedRowId}
                                                    />
                                                ) : null}

                                                <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                                                    <div className="col-4">
                                                        <label className="ml-1">Página {page}</label>
                                                    </div>
                                                    <div className="col-8 text-right">
                                                        {(page > 1 && page <= n_page) &&
                                                            <button className="btn btn-primary mr-1" type="button"
                                                                    onClick={() => {
                                                                        less_data();
                                                                    }}><i class="bx bx-arrow-back"></i></button>
                                                        }
                                                        {(page >= 1 && page < n_page) &&
                                                            <button className="btn btn-success" type="button"
                                                                    onClick={() => {
                                                                        more_data();
                                                                    }}><i class="bx bx-right-arrow-alt"></i></button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='email-user-list list-group'
                                                     style={openModal === true ? {display: "none"} : {}}>
                                                    <ul
                                                        className='users-list-wrapper media-list'
                                                        style={{
                                                            overflowY: "scroll",
                                                        }}
                                                    >
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => loadFunc()}
                                                            hasMore={HasMore}
                                                            useWindow={false}
                                                            loader={
                                                                <div className='col-12 d-flex justify-content-center'>
                                                                    <div
                                                                        className='spinner-border text-success'
                                                                        role='status'
                                                                    >
                                                                        <span className='sr-only'>Loading...</span>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            }
                                                        >
                                                            {departures.map((item, index) => (
                                                                <ItemList2
                                                                    item={item}
                                                                    title={item.establishment}
                                                                    reason_details
                                                                    subtitle={item.title}
                                                                    rightDescription={item.event_type}
                                                                    SecondrightDescription={`Estado: ${item.state}`}
                                                                    bulletType={get_status(item.state)}
                                                                    children={
                                                                        <div className='user-details'>
                                                                            {item.fecha_solicitud_aprobacion && (
                                                                                <div className='mail-items'>
                                          <span>
                                            Fecha de solicitud de aprobación:{" "}
                                          </span>
                                                                                    {item.fecha_solicitud_aprobacion}
                                                                                </div>
                                                                            )}
                                                                            {item.fecha_respuesta_aprobacion && (
                                                                                <div className='mail-meta-item'>
                                                                                    <span>Fecha de aprobación: </span>
                                                                                    {item.fecha_respuesta_aprobacion}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    }

                                                                    onClick={() => {
                                                                        if (item.event_type === "SALIDA EMPLEADO") {
                                                                            setSelectedRowID(item.id);
                                                                            setOpenModal(true);
                                                                        } else if (item.event_type === "VISITA EMPLEADO" || item.event_type === "VISITA PROVEEDOR" || item.event_type === "VISITA INVITADO") {
                                                                            setSelectedRowID(item.id);
                                                                            setOpenModal2(true)
                                                                        } else if (item.event_type === "INGRESO TIENDA") {
                                                                            setSelectedRowID(item.id);
                                                                            setOpenModal3(true)
                                                                        }

                                                                    }}
                                                                />
                                                            ))}
                                                        </InfiniteScroll>
                                                    </ul>
                                                    <div className='no-results'>
                                                        <i className='bx bx-error-circle font-large-2'/>
                                                        <h5>No Items Found</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleGenerateReport}>
                    <div className="card-body">
                        <h5 className="mb-2">Exportar datos</h5>
                        <div className="form-group">
                            <Input
                                type="date"
                                label="Fecha Inicio"
                                onChange={(e) => {
                                    setFechaInicial(e.target.value)
                                }}
                                name='fecha_inicial'
                                value={fecha_inicial}
                            />
                            <Input
                                type="date"
                                label="Fecha Fin"
                                onChange={(e) => {
                                    setFechaFinal(e.target.value)
                                }}
                                name='fecha_final'
                                value={fecha_final}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-success mb-1 button_div`} disabled={isLoading}>Exportar</button>
                        <button type="button" onClick={() => {
                            setFechaInicial('');
                            setFechaFinal('');
                            setModal(false);
                        }} className={`btn btn-outline-danger button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default VisitasSalidasMonitoreo;
