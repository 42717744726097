import React from "react";
import './fileMobile.css';
import Button from "../../components/button";
import {useHistory} from "react-router-dom";


const ConfirmPhoto = () => {
    let history = useHistory();

    const handleSubmit = () => {
        history.push("/");
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header text-center">
                            <h2 className="title">Información</h2>
                        </div>
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 text-center mt-1 order-md-1">
                                        <img
                                            className="img-fluid pt-1"
                                            src={
                                                window.location.origin +
                                                "/base/app-assets/images/icon/logo_si.png"
                                            }
                                            alt="branding logo"
                                            width="40%"
                                        />
                                        <div className="mt-2">
                                            <h4 className="card-body">Su fotografía se ha actualizado de forma
                                                exitosa.</h4>
                                            <Button
                                                text="Ir al sitio"
                                                theme="success"
                                                type="submit"
                                                classes="m-1"
                                                onClick={handleSubmit}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-center order-md-0">
                                        <img
                                            className="img-fluid pt-1"
                                            src={
                                                window.location.origin +
                                                "/base/app-assets/images/icon/foto_si.png"
                                            }
                                            alt="branding logo"
                                            width="80%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPhoto;
