import React from "react";

export const theme = (state) => {
  switch (state) {
    case "CREADO":
      return {
        backgroundColor: "#40ADF4",
        buttonColor: "#1A97F0",
      };
    case "EN ESPERA":
      return {
        backgroundColor: "#FFCA3A",
        buttonColor: "#e1b038",
      };
    case "APROBADO":
      return {
        backgroundColor: "#48D7A4",
        buttonColor: "#24CB8C",
      };
    case "ANULADO":
      return {
        backgroundColor: "#F44336",
        buttonColor: "#b92b20",
      };
    case "CADUCADO":
      return {
        backgroundColor: "#BDBDBD",
        buttonColor: "#757575",
      };
    case "SALIDA APROBADA":
      return {
        backgroundColor: "#40adf4",
        buttonColor: "#1A97F0",
      };
    case "SALIDA DENEGADA":
      return {
        backgroundColor: "#F44336",
        buttonColor: "#b92b20",
      };
      case "SALIDA RECHAZADA":
      return {
        backgroundColor: "#F44336",
        buttonColor: "#b92b20",
      };
    case " RETORNO RECHAZADO":
      return {
        backgroundColor: "#F44336",
        buttonColor: "#b92b20",
      };
    case "SALIDA REQUERIDA":
      return {
        backgroundColor: "#FFCA3A",
        buttonColor: "#e1b038",
      };
    case "SALIDA ACEPTADA":
      return {
        backgroundColor: "#48D7A4",
        buttonColor: "#24CB8C",
      };
    case "RETORNO ACEPTADO":
      return {
        backgroundColor: "#48D7A4",
        buttonColor: "#24CB8C",
      };
      case "RETORNO REQUERIDO":
        return {
          backgroundColor: "#FFCA3A",
          buttonColor: "#e1b038",
        };
    default:
      return {
        backgroundColor: "#40ADF4",
        buttonColor: "#1A97F0",
      };
  }
};

const CardColor = ({
  theme = {
    backgroundColor: "#40ADF4",
    buttonColor: "#1A97F0",
  },
  title,
  subtitle,
  description,
  button_text,
  onClick,
  estado,
  hora,
  grupo_permiso,
  onClickOclock,
}) => {
  return (
    <div
      className={`card`}
      style={{ width: "18rem", backgroundColor: theme.backgroundColor }}
    >
      <div className='card-body text-white'>
        <h5 className='card-title text-white text-nowrap text-truncate '>
          {title}
        </h5>
        <h6 className='card-subtitle mb-2 text-white'>{subtitle}</h6>
        <p className='card-text'>
          {description.length > 20
            ? description.substring(0, 20) + "..."
            : description}
        </p>

        <div
          style={{
            position: "relative",
            width: "20%",
            height: 40,
            bottom: 110,
            right: "-85%",
          }}
        >
          {estado === "APROBADO" &&
            hora === null &&
            grupo_permiso === "ESTABLECIMIENTOS" && (
              <>
                {/*eslint-disable-next-line*/}
                <a onClick={onClickOclock} href='#'>
                  {/*eslint-disable-next-line*/}
                  <img
                    className='img-fluid'
                    src={
                      window.location.origin +
                      "/base/app-assets/images/pages/clock.png"
                    }
                  />
                </a>
              </>
            )}
        </div>
      </div>
      <div className='card-footer d-flex justify-content-center'>
        <button
          onClick={onClick}
          className={`btn btn-block`}
          style={{ backgroundColor: theme.buttonColor, color: "#fff" }}
        >
          {button_text}
        </button>
      </div>
    </div>
  );
};

export default CardColor;
