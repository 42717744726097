import React from "react";
import "./dropzone.css";
const Dropzone = ({ handleChange, state, name,...props }) => {

  return (
    <div className="dropzone ">
      <label for={name} className="text-dark file-picker">
        <i
          className="bx bx-upload"
          style={{
            fontSize: "100px",
          }}
        ></i>
        <p className="text-center">{
            state? state.name : "Cargar"
        }</p>
      </label>
      <input
        id={name}
        type="file"
        name={name}
        onChange={(e) => handleChange(e)}
        {...props}
      ></input>
    </div>
  );
};

export default Dropzone;