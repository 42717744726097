import {
    URLAPI,
    VISITANT_PATH,
    VISITANT_CREATE_PATH,
    VISITANT_UPDATE_PATH,
    VISITANT_DELETE_PATH,

} from '../config/index';
import axios from 'axios';
import {LOGOUT} from './users'

/* Es el estado inicial del reductor. */
const init = {
    _get_all_visitants: {
        data: [],
        status: 0,
        message: {}
    },
    _create_visitant: {
        data: {},
        status: 0,
        message: {}
    },
    _update_visitant: {
        data: {},
        status: 0,
        message: {}
    },
    _delete_visitant: {
        data: {},
        status: 0,
        message: {}
    }
}

/* Una constante que se utiliza para identificar la acción que se enviará. */
const GET_ALL_VISITANTS = 'GET_ALL_VISITANTS';
/* Una constante que se utiliza para identificar la acción que se enviará. */
export const CREATE_VISITANT = 'CREATE_VISITANT';
/* Una constante que se utiliza para identificar la acción que se enviará. */
export const UPDATE_VISITANT = 'UPDATE_VISITANT';
export const DELETE_VISITANT = 'DELETE_VISITANT';


/**
 * Toma el estado actual y una acción, y luego devuelve el siguiente estado
 * @param [state] - El estado actual de la tienda.
 * @param action - El objeto de acción que se envió.
 * @returns El estado del reductor.
 */
export const visitant_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_VISITANTS:
            return {
                ...state,
                _get_all_visitants: action.payload
            }
        case CREATE_VISITANT:
            return {
                ...state,
                _create_visitant: action.payload
            }
        case UPDATE_VISITANT:
            return {
                ...state,
                _update_visitant: action.payload
            }
        case DELETE_VISITANT:
            return {
                ...state,
                _delete_visitant: action.payload
            }
        case LOGOUT:
            return init;
        default:
            return state;
    }
}


/**
 * Es una función que devuelve una función que devuelve un objeto. para guardar los datos de la api.
 * en la store de redux.
 * @param token - El token que se genera cuando el usuario inicia sesión.
 */
export const get_all_visitants = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${VISITANT_PATH}`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        });
        dispatch({
            type: GET_ALL_VISITANTS,
            payload: {
                data: response.data.datos,
                status: response.status,
                message: response.data.message
            }
        })
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_VISITANTS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje
                }
            })
        } else {
            dispatch({
                type: GET_ALL_VISITANTS,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message
                }
            })
        }
    }
}


/**
 * Es una función que realiza una petición POST para crear un nuevo visitante.
 * @param token - El token que se genera cuando el usuario inicia sesión.
 * @param data - Los datos que se enviarán como cuerpo de la solicitud.
 */
export const create_visitant = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();
        for (let key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(`${URLAPI}${VISITANT_CREATE_PATH}`, form, {
            headers: {
                'Authorization': `Token ${token}`
            }
        });
        dispatch({
            type: CREATE_VISITANT,
            payload: {
                data: response.data,
                status: response.status,
                message: ""
            }
        })
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_VISITANT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje
                }
            })
        } else {
            dispatch({
                type: CREATE_VISITANT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message
                }
            })
        }
    }
}


/**
 * Hace una solicitud PATCH a la API y envía una acción con la respuesta
 * @param token - El token que se genera cuando el usuario inicia sesión.
 * @param id - La identificación del visitante que se actualizará.
 * @param data - Los datos que se enviarán como cuerpo de la solicitud.
 */
export const update_visitant = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();
        for (let key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(`${URLAPI}${VISITANT_UPDATE_PATH}${id}/`, form, {
            headers: {
                'Authorization': `Token ${token}`
            }
        });
        dispatch({
            type: UPDATE_VISITANT,
            payload: {
                data: response.data,
                status: response.status,
                message: ""
            }
        })
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_VISITANT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje
                }
            })
        } else {
            dispatch({
                type: UPDATE_VISITANT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message
                }
            })
        }
    }
}

export const delete_visitant = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(`${URLAPI}${VISITANT_DELETE_PATH}${id}/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        });
        dispatch({
            type: DELETE_VISITANT,
            payload: {
                data: response.data.mensaje,
                status: response.status,
                message: ""
            }
        })
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_VISITANT,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje
                }
            })
        } else {
            dispatch({
                type: DELETE_VISITANT,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message
                }
            })
        }
    }
}

/**
 * Es una función que toma un tipo como parámetro y devuelve una función que toma un despacho como
 * parámetro y devuelve un objeto con un tipo y una carga útil
 * @param type - El tipo de acción.
 */
export const clear_visitant = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {}
        }
    })
}


