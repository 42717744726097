import {
    URLAPI,
    JOBS_PATH,
    JOBS_CREATE_PATH,
    JOBS_UPDATE_PATH,
} from "../config/index";
import axios from "axios";
import {LOGOUT} from './users'

const init = {
    _get_all_jobs: {
        data: {},
        status: 0,
        message: "",
    },
    _create_job: {
        data: {},
        status: 0,
        message: "",
    },
    _update_job: {
        data: {},
        status: 0,
        message: "",
    },
};

const GET_ALL_JOBS = "_get_all_jobs";
export const CREATE_JOB = "_create_job";
export const UPDATE_JOB = "_update_job";

export const jobs_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_JOBS:
            return {
                ...state,
                _get_all_jobs: action.payload,
            };
        case CREATE_JOB:
            return {
                ...state,
                _create_job: action.payload,
            };
        case UPDATE_JOB:
            return {
                ...state,
                _update_job: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllJobs = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${JOBS_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_JOBS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_JOBS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_JOBS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const createJob = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${JOBS_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: CREATE_JOB,
            payload: {
                data: response.data,
                status: response.status,
                message: ""
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_JOB,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_JOB,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateJob = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${JOBS_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_JOB,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: UPDATE_JOB,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_JOB,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const clear_jobs = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: "",
        },
    });
}